<template>
  <!-- BEGIN about-us -->
  <div class="about-us text-center">
    <h1>{{ data.title }}</h1>
    <p>
      {{ data.subtitle }}
    </p>
  </div>
  <!-- END about-us -->
</template>

<script>

export default {
  components: {  },
  name: "About",
  props: ["data"],
  data() {
    return {};
  },
};
</script>
