<template>
  <div>
    <!-- ================== BEGIN core-css ================== -->
    <link
      href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700"
      rel="stylesheet"
    />
    <link href="assets/css/e-commerce/vendor.min.css" rel="stylesheet" />
    <link href="assets/css/e-commerce/app.min.css" rel="stylesheet" />
    <!-- ================== END core-css ================== -->

    <!-- BEGIN #page-container -->
    <div id="page-container" class="fade show">
      <Navtop></Navtop>
      <NavbarEcom></NavbarEcom>

      <!-- BEGIN #about-us-cover -->
      <div id="about-us-cover" class="has-bg section-container">
        <!-- BEGIN cover-bg -->
        <div class="cover-bg">
          <img v-bind:src="imgSrc" alt="" />
        </div>
        <!-- END cover-bg -->

        <!-- BEGIN container -->
        <div class="container">
          <!-- BEGIN breadcrumb -->
          <Breadcrumb v-bind:breadcrumbs="breadcrumbs" classList="breadcrumb mb-2"></Breadcrumb>

          <!-- <ul class="breadcrumb">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item active">About Us</li>
          </ul> -->
          <!-- END breadcrumb -->

          <About v-bind:data="pageBanner"></About>
        </div>
        <!-- END container -->
      </div>
      <!-- END #about-us-cover -->

      <!-- BEGIN #about-us-content -->
      <div id="about-us-content" class="section-container bg-white">
        <!-- BEGIN container -->
        <div class="container">
          <!-- BEGIN about-us-content -->
          <div class="about-us-content">
            <h2 class="title text-center">{{ sectionHeading1 }}</h2>
            <p class="desc text-center" v-html="sectionText1"></p>
            <!-- BEGIN row -->
            <div class="row">
              <AboutCol v-for="(d, i) in abouts1" v-bind:key="i" v-bind:data="d"></AboutCol>

              <!-- <AboutCol
                classList="fa fa-truck"
                classList2="icon text-muted"
                title="Curabitur"
                description="Duis in lorem placerat, iaculis nisi vitae, ultrices tortor. Vestibulum molestie ipsum nulla. Maecenas nec hendrerit eros, sit amet maximus leo."
              ></AboutCol>
      
              <AboutCol
                classList="fa fa-universal-access"
                classList2="icon text-blue"
                title="Phasellus"
                description="Aenean et elementum dui. Aenean massa enim, suscipit ut molestie quis, pretium sed orci. Ut faucibus egestas mattis."
              ></AboutCol>
 
              <AboutCol
                classList="fa fa-assistive-listening-systems"
                classList2="icon text-info"
                title="Pellentesque"
                description="Quisque gravida metus in sollicitudin feugiat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos."
              ></AboutCol> -->
            </div>
            <!-- END row -->
            <hr />

            <!-- BEGIN row -->
            <div class="row">
              <AboutCol v-for="(d, i) in abouts2" v-bind:key="i" v-bind:data="d"></AboutCol>

              <!-- <AboutCol
                classList="fab fa-cc-discover"
                classList2="icon text-danger"
                title="Vestibulum"
                description="Etiam nulla turpis, gravida et orci ac, viverra commodo ipsum. Donec nec mauris faucibus, congue nisi sit amet, lobortis arcu."
              ></AboutCol>
              <AboutCol
                classList="fab fa-connectdevelop"
                classList2="icon text-inverse"
                title="Curabitur"
                description="Ut vel laoreet tortor. Donec venenatis ex velit, eget bibendum purus accumsan cursus. Curabitur pulvinar iaculis diam."
              ></AboutCol>
              <AboutCol
                classList="fa fa-braille"
                classList2="icon text-success"
                title="Phasellus"
                description="Integer consectetur, massa id mattis tincidunt, sapien erat malesuada turpis, nec vehicula lacus felis nec libero. Fusce non lorem nisl."
              ></AboutCol> -->
            </div>
            <!-- END row -->
          </div>
          <!-- END about-us-content -->
        </div>
        <!-- END container -->
      </div>
      <!-- END #about-us-content -->
    </div>
    <!-- END #page-container -->

    <Footer></Footer>
  </div>
</template>

<script>
import Navtop from "@/components/Navtop.vue";
import NavbarEcom from "@/components/NavbarEcom.vue";
import Footer from "@/components/Footer.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import About from "@/components/About.vue";
import AboutCol from "@/components/AboutCol.vue";

export default {
  components: {
    Footer,
    NavbarEcom,
    Navtop,
    Breadcrumb,
    About,
    AboutCol,
  },
  name: "AboutPage",
  data() {
    return {
      breadcrumbs: [
        { link: "#", name: "Home" },
        { link: "#", name: "About Us" },
      ],
      pageBanner:{
        title:"About SeanTheme",
        subtitle:"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur lobortis tortor justo, elementum volutpat ante porta eu."
      },
      imgSrc: "assets/img/cover/cover-13.jpg",
      sectionHeading1: "What we do?",
      sectionText1:
        "Vestibulum ut enim nec justo imperdiet posuere nec quis odio.<br />Nullam non tellus sed urna elementum vitae a erat.",
      abouts1: [
        {
          classList: "fa fa-truck",
          classList2: "icon text-muted",
          title: "Curabitur",
          description:
            "Duis in lorem placerat, iaculis nisi vitae, ultrices tortor. Vestibulum molestie ipsum nulla. Maecenas nec hendrerit eros, sit amet maximus leo.",
        },
        {
          classList: "fa fa-universal-access",
          classList2: "icon text-blue",
          title: "Phasellus",
          description:
            "Aenean et elementum dui. Aenean massa enim, suscipit ut molestie quis, pretium sed orci. Ut faucibus egestas mattis.",
        },
        {
          classList: "fa fa-assistive-listening-systems",
          classList2: "icon text-info",
          title: "Pellentesque",
          description:
            "Quisque gravida metus in sollicitudin feugiat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
        },
      ],
      abouts2: [
        {
          classList: "fab fa-cc-discover",
          classList2: "icon text-danger",
          title: "Vestibulum",
          description:
            "Etiam nulla turpis, gravida et orci ac, viverra commodo ipsum. Donec nec mauris faucibus, congue nisi sit amet, lobortis arcu.",
        },
        {
          classList: "fab fa-connectdevelop",
          classList2: "icon text-inverse",
          title: "Curabitur",
          description:
            "Ut vel laoreet tortor. Donec venenatis ex velit, eget bibendum purus accumsan cursus. Curabitur pulvinar iaculis diam.",
        },
        {
          classList: "fa fa-braille",
          classList2: "icon text-success",
          title: "Phasellus",
          description:
            "Integer consectetur, massa id mattis tincidunt, sapien erat malesuada turpis, nec vehicula lacus felis nec libero. Fusce non lorem nisl.",
        },
      ],
    };
  },
  watch: {
    $route: {
      handler: (to) => {
        document.title = to.meta.title;
      },
      immediate: true,
    },
  },
};
</script>
