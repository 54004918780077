<template>
	<li>
								<div class="image">
									<img v-bind:src="imgSrc" alt="" />
								</div>
								<div class="info">
									<h4 class="info-title">{{title}}</h4>
									<div class="price">${{price}}</div>
								</div>
							</li>
</template>

<script>
export default {
  name: "LatestProductLink",
  props: ["title", "price", "imgSrc" ],
  data() {
    return {};
  },
};
</script>
