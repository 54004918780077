<template>
  <div>
    <!-- BEGIN #trending-items -->
    <div id="trending-items" class="section-container">
      <!-- BEGIN container -->
      <div class="container">
        <!-- BEGIN section-title -->
        <h4 class="section-title clearfix">
          <span class="flex-1">
            Trending Items
            <small>Shop and get your favourite items at amazing prices!</small>
          </span>
          <div class="btn-group">
            <a href="#" class="btn"><i class="fa fa-angle-left fs-16px"></i></a>
            <a href="#" class="btn"><i class="fa fa-angle-right fs-16px"></i></a>
          </div>
        </h4>
        <!-- END section-title -->
        <!-- BEGIN row -->
        <div class="row gx-2">
          <TrendingItem
            v-for="(d, i) in trendingItems"
            v-bind:key="i"
            v-bind:data="d"
          ></TrendingItem>

          <!-- <TrendingItem
            title="iPhone 6s Plus<br />16GB"
            price="749"
            imgSrc="assets/img/product/product-iphone.png"
            description="3D Touch. 12MP photos. 4K video."
            link="product_detail"
            disPrice="649"
            discount="15"
          ></TrendingItem>

          <TrendingItem
            title="9.7-inch iPad Pro<br />32GB"
            price="799"
            imgSrc="assets/img/product/product-ipad-pro.png"
            description="Super. Computer. Now in two sizes."
            link="product_detail"
            disPrice="599"
            discount="32"
          ></TrendingItem>

          <TrendingItem
            title="21.5-inch iMac<br />with Retina Display"
            price="1,299.00"
            imgSrc="assets/img/product/product-imac.png"
            description="Retina. Now in colossal and ginormous."
            link="product_detail"
            disPrice="1,099.00"
            discount="20"
          ></TrendingItem>

          <TrendingItem
            title="Apple Watch<br />Stainless steel cases"
            price="799.00"
            imgSrc="assets/img/product/product-apple-watch.png"
            description="You. At a glance."
            link="product_detail"
            disPrice="599.00"
            discount="13"
          ></TrendingItem>

          <TrendingItem
            title="MacBook Pro<br />with Retina Display"
            price="1499.00"
            imgSrc="assets/img/product/product-macbook-pro.png"
            description="Stunning Retina Display"
            link="product_detail"
            disPrice="1299.00"
            discount="30"
          ></TrendingItem>

          <TrendingItem
            title="Apple Tv<br />32GB"
            price="249.00"
            imgSrc="assets/img/product/product-apple-tv.png"
            description="The future of television is here."
            link="product_detail"
            disPrice="149.00"
            discount="40"
          ></TrendingItem> -->
        </div>
      </div>
      <!-- END container -->
    </div>
    <!-- END #trending-items -->
  </div>
</template>

<script>
import TrendingItem from "@/components/TrendingItem.vue";

export default {
  components: { TrendingItem },
  name: "Trending",
  props: [""],
  data() {
    return {
      trendingItems: [
        {
          title: "iPhone 6s Plus<br />16GB",
          price: "749",
          imgSrc: "assets/img/product/product-iphone.png",
          description: "3D Touch. 12MP photos. 4K video.",
          link: "product_detail",
          disPrice: "649",
          discount: "15"
        },
        {
          title: "9.7-inch iPad Pro<br />32GB",
          price: "799",
          imgSrc: "assets/img/product/product-ipad-pro.png",
          description: "Super. Computer. Now in two sizes.",
          link: "product_detail",
          disPrice: "599",
          discount: "32"
        },
         {
          title: "21.5-inch iMac<br />with Retina Display",
          price: "1,299.00",
          imgSrc: "assets/img/product/product-imac.png",
          description: "Retina. Now in colossal and ginormous.",
          link: "product_detail",
          disPrice: "1,099.00",
          discount: "20"
        },
         {
          title: "Apple Watch<br />Stainless steel cases",
          price: "799.00",
          imgSrc: "assets/img/product/product-apple-watch.png",
          description: "You. At a glance.",
          link: "product_detail",
          disPrice: "599.00",
          discount: "13"
        },
         {
          title: "MacBook Pro<br />with Retina Display",
          price: "1499.00",
          imgSrc: "assets/img/product/product-macbook-pro.png",
          description: "Stunning Retina Display",
          link: "product_detail",
          disPrice: "1299.00",
          discount: "30"
        },
         {
          title: "Apple Tv<br />32GB",
          price: "249.00",
          imgSrc: "assets/img/product/product-apple-tv.png",
          description: "The future of television is here.",
          link: "product_detail",
          disPrice: "149.00",
          discount: "40"
        }
      ],
    };
  },
};
</script>
