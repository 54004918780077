<template>
  <!-- BEGIN policy -->
  <div class="policy">
    <div class="policy-icon"><i v-bind:class="classList"></i></div>
    <div class="policy-info">
      <h4>{{title}}</h4>
      <p>{{text}}</p>
    </div>
  </div>
  <!-- END policy -->
</template>

<script>
export default {
  name: "PolicyItem",
  props: ["title", "text", "classList" ],
  data() {
    return {};
  },
};
</script>
