<template>
    
    <div> 



<!-- begin #page-title -->
	<div id="page-title" class="page-title has-bg">
		<div class="bg-cover" data-paroller="true" 
		data-paroller-factor="0.5" data-paroller-factor-xs="0.2"
		 v-bind:style="data.style"></div>
		<div class="container">
			<h1>{{data.title}}</h1>
			<p>{{data.subtitle}}</p>
		</div>
	</div>
	<!-- end #page-title -->


</div>


</template>



<script>


export default {
  components: {  },
  name: "PageTitle",
   props: ["data"],
  data() {
    return {
     
    };
  },
  
};
</script>



