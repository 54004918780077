<template>
	<img v-bind:src="imgSrc" alt="" />
</template>

<script>
export default {
  name: "Picture",
  props: [ "imgSrc"],
  data() {
    return {};
  },
};
</script>
