<template>
  <div>
    <!-- BEGIN similar-product -->
    <h4 class="mb-15px mt-30px">You Might Also Like</h4>
    <div class="row gx-2">
      <div class="col-lg-2 col-md-4" v-for="(d, i) in moreItems" v-bind:key="i">
        <ProductItem v-bind:data="d"></ProductItem>
      </div>

      <!-- <div class="col-lg-2 col-md-4">
        <div class="item item-thumbnail">
          <a href="product_detail.html" class="item-image">
            <img src="assets/img/product/product-iphone.png" alt="" />
            <div class="discount">15% OFF</div>
          </a>
          <div class="item-info">
            <h4 class="item-title">
              <a href="product_detail.html">iPhone 6s Plus<br />16GB</a>
            </h4>
            <p class="item-desc">3D Touch. 12MP photos. 4K video.</p>
            <div class="item-price">$649.00</div>
            <div class="item-discount-price">$739.00</div>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-4">
        <div class="item item-thumbnail">
          <a href="product_detail.html" class="item-image">
            <img src="assets/img/product/product-samsung-note5.png" alt="" />
            <div class="discount">32% OFF</div>
          </a>
          <div class="item-info">
            <h4 class="item-title">
              <a href="product.html">Samsung Galaxy Note 5<br />Black</a>
            </h4>
            <p class="item-desc">Super. Computer. Now in two sizes.</p>
            <div class="item-price">$599.00</div>
            <div class="item-discount-price">$799.00</div>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-4">
        <div class="item item-thumbnail">
          <a href="product_detail.html" class="item-image">
            <img src="assets/img/product/product-iphone-se.png" alt="" />
            <div class="discount">20% OFF</div>
          </a>
          <div class="item-info">
            <h4 class="item-title">
              <a href="product.html">iPhone SE<br />32/64Gb</a>
            </h4>
            <p class="item-desc">A big step for small.</p>
            <div class="item-price">$499.00</div>
            <div class="item-discount-price">$599.00</div>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-4">
        <div class="item item-thumbnail">
          <a href="product_detail.html" class="item-image">
            <img src="assets/img/product/product-zenfone2.png" alt="" />
            <div class="discount">15% OFF</div>
          </a>
          <div class="item-info">
            <h4 class="item-title">
              <a href="product_detail.html">Assus ZenFone 2<br />‏(ZE550ML)</a>
            </h4>
            <p class="item-desc">See What Others Can’t See</p>
            <div class="item-price">$399.00</div>
            <div class="item-discount-price">$453.00</div>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-4">
        <div class="item item-thumbnail">
          <a href="product_detail.html" class="item-image">
            <img src="assets/img/product/product-xperia-z.png" alt="" />
            <div class="discount">32% OFF</div>
          </a>
          <div class="item-info">
            <h4 class="item-title">
              <a href="product.html">Sony Xperia Z<br />Black Color</a>
            </h4>
            <p class="item-desc">For unexpectedly beautiful moments</p>
            <div class="item-price">$599.00</div>
            <div class="item-discount-price">$799.00</div>
          </div>
        </div>
   
      </div>
      <div class="col-lg-2 col-md-4">
        <div class="item item-thumbnail">
          <a href="product_detail.html" class="item-image">
            <img src="assets/img/product/product-lumia-532.png" alt="" />
            <div class="discount">20% OFF</div>
          </a>
          <div class="item-info">
            <h4 class="item-title">
              <a href="product.html">Microsoft Lumia 531<br />Smartphone Orange</a>
            </h4>
            <p class="item-desc">1 Year Local Manufacturer Warranty</p>
            <div class="item-price">$99.00</div>
            <div class="item-discount-price">$199.00</div>
          </div>
        </div> 
        </div> -->
    </div>
    <!-- END similar-product -->
  </div>
</template>

<script>
import ProductItem from "@/components/ProductItem.vue";

export default {
  components: {  ProductItem },
  name: "ProductsSimilar",
  props: [""],
  data() {
    return {
      moreItems: [
        {
          link: "product",
          discount: "15",
          title: "iPhone 6s Plus<br />16GB",
          description: "3D Touch. 12MP photos. 4K video.",
          disPrice: "649",
          price: "739",
          imgSrc: "assets/img/product/product-iphone.png",
        },
        {
          link: "product",
          discount: "32",
          title: "Samsung Galaxy Note 5<br />Black",
          description: "Super. Computer. Now in two sizes.",
          disPrice: "599",
          price: "799",
          imgSrc: "assets/img/product/product-samsung-note5.png",
        },
        {
          link: "product",
          discount: "20",
          title: "iPhone SE<br />32/64Gb",
          description: "A big step for small.",
          disPrice: "499",
          price: "599",
          imgSrc: "assets/img/product/product-iphone-se.png",
        },
        {
          link: "product",
          discount: "15",
          title: "Assus ZenFone 2<br />‏(ZE550ML)",
          description: "See What Others Can’t See",
          disPrice: "399",
          price: "453",
          imgSrc: "assets/img/product/product-zenfone2.png",
        },
        {
          link: "product",
          discount: "32",
          title: "Sony Xperia Z<br />Black Color",
          description: "For unexpectedly beautiful moments",
          disPrice: "599",
          price: "799",
          imgSrc: "assets/img/product/product-xperia-z.png",
        },
        {
          link: "product",
          discount: "20",
          title: "Microsoft Lumia 531<br />Smartphone Orange",
          description: "1 Year Local Manufacturer Warranty",
          disPrice: "99",
          price: "199",
          imgSrc: "assets/img/product/product-lumia-532.png",
        },
      ],
    };
  },
};
</script>
