<template>
     <!-- begin section-container -->
            <div class="section-container">
              <h4 class="section-title"><span>{{data.title}}</span></h4>
              <ul class="sidebar-recent-post">
                <LinkRecentPost
                 v-for="(d, i) in data.links"
                  v-bind:key="i"
                  v-bind:link="d.link"
                  v-bind:text="d.text"
                  v-bind:pubDate="d.pubDate"
        ></LinkRecentPost>

                <!-- <LinkRecentPost
                  link="#"
                  text="Lorem ipsum dolor sit amet."
                  pubDate="23 December 2018"
                ></LinkRecentPost>
                <LinkRecentPost
                  link="#"
                  text="Vestibulum a cursus arcu."
                  pubDate="16 December 2018"
                ></LinkRecentPost>
                <LinkRecentPost
                  link="#"
                  text="Nullam vel condimentum lectus."
                  pubDate="7 December 2018"
                ></LinkRecentPost>
                <LinkRecentPost
                  link="#"
                  text="Proin in dui egestas libero posuere ullamcorper."
                  pubDate="20 November 2018"
                ></LinkRecentPost>
                <LinkRecentPost
                  link="#"
                  text="Interdum et malesuada fames ac ante."
                  pubDate="4 November 2018"
                ></LinkRecentPost> -->
              </ul>
            </div>
            <!-- end section-container -->

</template>

<script>
import LinkRecentPost from "@/components/LinkRecentPost";

export default {
  components: {
    LinkRecentPost,
  },
  name: "SidebarRecentSection",
  props: ["link", "text", "count", "data"],
  data() {
    return {};
  },
};
</script>
