<template>
  <li>
    <a v-bind:href="link"
      ><i v-bind:class="classList"></i> {{ text }} <img v-if="imgSrc" v-bind:src="imgSrc" alt=""
    /></a>
  </li>
</template>

<script>
export default {
  name: "LinkTextMenu",
  props: ["link", "text", "classList", "imgSrc"],
  data() {
    return {};
  },
};
</script>
