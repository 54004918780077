<template>
  <!-- BEGIN item -->
  <div class="item item-thumbnail">
    <router-link v-bind:to="data.link" class="item-image">
      <img v-bind:src="data.imgSrc" alt="" />
      <div class="discount">{{ data.discount }}% OFF</div>
    </router-link>
    <div class="item-info">
      <h4 class="item-title">
        <router-link v-bind:to="data.link" v-html="data.title"></router-link>
      </h4>
      <p class="item-desc">{{ data.description }}</p>
      <div class="item-price">${{ data.disPrice }}</div>
      <div class="item-discount-price">${{ data.price }}</div>
    </div>
  </div>
  <!-- END item -->
</template>

<script>
export default {
  name: "ProductItem",
  props: ["data"],
  data() {
    return {};
  },
};
</script>
