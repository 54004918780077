<template>
  <div>
    <!-- ================== BEGIN core-css ================== -->
    <link
      href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700"
      rel="stylesheet"
    />
    <link href="assets/css/e-commerce/vendor.min.css" rel="stylesheet" />
    <link href="assets/css/e-commerce/app.min.css" rel="stylesheet" />
    <!-- ================== END core-css ================== -->

    <!-- BEGIN #page-container -->
    <div id="page-container" class="fade show">
      <Navtop></Navtop>
      <NavbarEcom></NavbarEcom>

      <!-- BEGIN #product -->
      <div id="product" class="section-container pt-20px">
        <!-- BEGIN container -->
        <div class="container">

          <!-- BEGIN breadcrumb -->
          <Breadcrumb v-bind:breadcrumbs="breadcrumbs" classList="breadcrumb"></Breadcrumb>
          <!-- END breadcrumb -->

          <ProductDetail></ProductDetail>
          <ProductsSimilar></ProductsSimilar>
        </div>
        <!-- END container -->
      </div>
      <!-- END #product -->
    </div>
    <!-- END #page-container -->

    <Footer></Footer>
  </div>
</template>

<script>
import Navtop from "@/components/Navtop.vue";
import NavbarEcom from "@/components/NavbarEcom.vue";
import Footer from "@/components/Footer.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import ProductDetail from "@/components/ProductDetail.vue";
import ProductsSimilar from "@/components/ProductsSimilar.vue";

export default {
  components: {
    Footer,
    NavbarEcom,
    Navtop,
    Breadcrumb,
    ProductDetail,
    ProductsSimilar,
  },
  name: "ProductDetailPage",
  data() {
    return {
      breadcrumbs: [
        { link: "#", name: "Home" },
        { link: "#", name: "Mobile PHone" },
        { link: "#", name: "Apple" },
        { link: "#", name: "iPhone 6S Plus" },
      ],
    };
  },
  watch: {
    $route: {
      handler: (to) => {
        document.title = to.meta.title;
      },
      immediate: true,
    },
  },
};
</script>
