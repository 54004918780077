<template>
  <div>
    <!-- BEGIN #policy -->
    <div id="policy" class="section-container bg-white">
      <!-- BEGIN container -->
      <div class="container">
        <!-- BEGIN row -->
        <div class="row">
          <!-- BEGIN col-4 -->
          <div class="col-lg-4 col-md-4 mb-4 mb-md-0">
            <!-- BEGIN policy -->
            <PolicyItem
              title="Free Delivery Over $100"
              text="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
              classList="fa fa-truck"
            ></PolicyItem>
            <!-- END policy -->
          </div>
          <!-- END col-4 -->
          <!-- BEGIN col-4 -->
          <div class="col-lg-4 col-md-4 mb-4 mb-md-0">
            <!-- BEGIN policy -->
            <PolicyItem
              title="1 Year Warranty For Phones"
              text="Cras laoreet urna id dui malesuada gravida. <br />Duis a lobortis dui."
              classList="fa fa-umbrella"
            ></PolicyItem>
            <!-- END policy -->
          </div>
          <!-- END col-4 -->
          <!-- BEGIN col-4 -->
          <div class="col-lg-4 col-md-4">
            <!-- BEGIN policy -->
            <PolicyItem
              title="6 Month Warranty For Accessories"
              text="Fusce ut euismod orci. Morbi auctor, sapien non eleifend iaculis."
              classList="fa fa-user-md"
            ></PolicyItem>
            <!-- END policy -->
          </div>
          <!-- END col-4 -->
        </div>
        <!-- END row -->
      </div>
      <!-- END container -->
    </div>
    <!-- END #policy -->
  </div>
</template>

<script>
import PolicyItem from "@/components/PolicyItem.vue";

export default {
  components: { PolicyItem },
  name: "Policy",
  props: [""],
  data() {
    return {};
  },
};
</script>
