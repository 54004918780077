<template>
  <div>
    <!-- ================== BEGIN core-css ================== -->
    <link
      href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700"
      rel="stylesheet"
    />
    <link href="assets/css/e-commerce/vendor.min.css" rel="stylesheet" />
    <link href="assets/css/e-commerce/app.min.css" rel="stylesheet" />
    <!-- ================== END core-css ================== -->

    <!-- BEGIN #page-container -->
    <div id="page-container" class="fade show">
      <Navtop></Navtop>
      <NavbarEcom></NavbarEcom>

      <!-- BEGIN #my-account -->
      <div id="about-us-cover" class="section-container pt-20px">
        <!-- BEGIN container -->
        <div class="container">
          <!-- BEGIN breadcrumb -->
          <Breadcrumb v-bind:breadcrumbs="breadcrumbs" classList="breadcrumb mb-2"></Breadcrumb>
          <!-- END breadcrumb -->

          <!-- BEGIN account-container -->
          <div class="account-container">
            <!-- BEGIN account-sidebar -->
            <AccountSidebar></AccountSidebar>
            <!-- END account-sidebar -->
            <!-- BEGIN account-body -->
            <div class="account-body">
              <!-- BEGIN row -->
              <div class="row">
                <!-- BEGIN col-6 -->
                <div class="col-md-6">
                  <h4><i class="fab fa-gitlab fa-fw text-primary"></i> Orders</h4>
                  <ul class="nav nav-list">
                    <LinkTextList
                      v-for="(d, i) in links1"
                      v-bind:key="i"
                      v-bind:data="d"
                    ></LinkTextList>

                    <!-- <li><a href="#">Check the status of an order</a></li>
                    <li><a href="#">Track a shipment</a></li>
                    <li><a href="#">Pre-sign for a delivery</a></li>
                    <li><a href="#">Cancel items</a></li>
                    <li><a href="#">Print an invoice</a></li>
                    <li><a href="#">Return items</a></li>
                    <li><a href="#">Change shipping or billing info for an order</a></li>
                    <li><a href="#">Edit gift messaging or engraving</a></li>
                    <li><a href="#">View order history</a></li> -->
                  </ul>
                  <h4>
                    <i class="fa fa-universal-access fa-fw text-primary"></i> Account Settings
                  </h4>
                  <ul class="nav nav-list">
                    <LinkTextList
                      v-for="(d, i) in links2"
                      v-bind:key="i"
                      v-bind:data="d"
                    ></LinkTextList>

                    <!-- <li><a href="#">Update your email address and password</a></li>
                    <li><a href="#">Change your default shipping or billing info</a></li>
                    <li><a href="#">Manage email subscriptions</a></li> -->
                  </ul>
                </div>
                <!-- END col-6 -->
                <!-- BEGIN col-6 -->
                <div class="col-md-6">
                  <h4><i class="fab fa-cc-visa fa-fw text-primary"></i> Payments & Financing</h4>
                  <ul class="nav nav-list">
                       <LinkTextList
                      v-for="(d, i) in links3"
                      v-bind:key="i"
                      v-bind:data="d"
                    ></LinkTextList>

                    <!-- <li><a href="#">Check the balance of a gift card</a></li>
                    <li><a href="#">Check the status of a rebate</a></li> -->
                  </ul>
                  <h4><i class="fab fa-wpforms fa-fw text-primary"></i> Specialists</h4>
                  <ul class="nav nav-list">
                       <LinkTextList
                      v-for="(d, i) in links4"
                      v-bind:key="i"
                      v-bind:data="d"
                    ></LinkTextList>

                    <!-- <li><a href="#">View your previous activity</a></li> -->
                  </ul>
                </div>
                <!-- END col-6 -->
              </div>
              <!-- END row -->
            </div>
            <!-- END account-body -->
          </div>
          <!-- END account-container -->
        </div>
        <!-- END container -->
      </div>
      <!-- END #about-us-cover -->
    </div>
    <!-- END #page-container -->

    <Footer></Footer>
  </div>
</template>

<script>
import Navtop from "@/components/Navtop.vue";
import NavbarEcom from "@/components/NavbarEcom.vue";
import Footer from "@/components/Footer.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import AccountSidebar from "@/components/AccountSidebar.vue";
import LinkTextList from "@/components/LinkTextList.vue";

export default {
  components: {
    Footer,
    NavbarEcom,
    Navtop,
    Breadcrumb,
    AccountSidebar,
    LinkTextList,
  },
  name: "AccountPage",
  data() {
    return {
      breadcrumbs: [
        { link: "#", name: "Home" },
        { link: "#", name: "Store" },
        { link: "#", name: "My Account" },
      ],
      links1: [
        { link: "product_item", text: "Check the status of an order" },
        { link: "product_item", text: "Track a shipment" },
        { link: "product_item", text: "Pre-sign for a delivery" },
        { link: "product_item", text: "Cancel items" },
        { link: "product_item", text: "Print an invoice" },
        { link: "product_item", text: "Return items" },
        { link: "product_item", text: "Change shipping or billing info for an order" },
        { link: "product_item", text: "Edit gift messaging or engraving" },
        { link: "product_item", text: "View order history" },
      ],
      links2: [
        { link: "product_item", text: "Update your email address and password" },
        { link: "product_item", text: "Change your default shipping or billing info" },
        { link: "product_item", text: "Manage email subscriptions" },
      ],
      links3: [
        { link: "product_item", text: "Check the balance of a gift card" },
        { link: "product_item", text: "Check the status of a rebate" },
     
      ],
       links4: [
        { link: "product_item", text: "View your previous activity" },
     
      ],
    };
  },
  watch: {
    $route: {
      handler: (to) => {
        document.title = to.meta.title;
      },
      immediate: true,
    },
  },
};
</script>
