<template>
  <div>
    <!-- ================== BEGIN BASE CSS STYLE ================== -->
    <link
      href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700"
      rel="stylesheet"
    />
    <link href="../assets/css/blog/vendor.min.css" rel="stylesheet" />
    <link href="../assets/css/blog/app.min.css" rel="stylesheet" />
    <!-- ================== END core-css ================== -->

    <NavbarBlog></NavbarBlog>
    <PageTitle v-bind:data="PageTitle"></PageTitle>

    <BlogContent></BlogContent>
    <FooterBlog></FooterBlog>
  </div>
</template>

<script>
import BlogContent from "@/components/BlogContent.vue";
import PageTitle from "@/components/PageTitle";
import NavbarBlog from "@/components/NavbarBlog.vue";
import FooterBlog from "@/components/FooterBlog.vue";

export default {
  components: { PageTitle, BlogContent, FooterBlog, NavbarBlog },
  name: "BlogMainPage",
data (){
  return {
   PageTitle:{
        style:"background: url(assets/img/cover/cover-4.jpg) center 0px / cover no-repeat",
        title: "Official Color Admin Blog",
        subtitle: "Blog Concept Front End Page"
      },
  }
},
  watch: {
    $route: {
      handler: (to) => {
        document.title = to.meta.title;
      },
      immediate: true,
    },
  },
};
</script>
