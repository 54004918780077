<template>
 
 			<!-- BEGIN search-sidebar -->
					<div class="search-sidebar">
						<h4 class="title">Filter By</h4>
						<form action="product" method="POST" name="filter_form">
							<div class="mb-3">
								<label class="form-label">Keywords</label>
								<input type="text" class="form-control input-sm" name="keyword" value="Mobile Phones" placeholder="Enter Keywords" />
							</div>
							<div class="mb-3">
								<label class="form-label">Price</label>
								<div class="row row-space-0">
									<div class="col-md-5">
										<input type="number" class="form-control input-sm" name="price_from" value="10" placeholder="Price From" />
									</div>
									<div class="col-md-2 text-center pt-5px fs-12px text-muted">to</div>
									<div class="col-md-5">
										<input type="number" class="form-control input-sm" name="price_to" value="10000" placeholder="Price To" />
									</div>
								</div>
							</div>
							<div class="mb-30px">
								<button type="submit" class="btn btn-sm btn-theme btn-inverse w-100"><i class="fa fa-search fa-fw me-1 ms-n3"></i> FILTER</button>
							</div>
						</form>
						<h4 class="title mb-0">Categories</h4>
						<ul class="search-category-list">
							<li><a href="#">iPhone <span class="pull-right">(10)</span></a></li>
							<li><a href="#">Mac <span class="pull-right">(15)</span></a></li>
							<li><a href="#">iPad <span class="pull-right">(32)</span></a></li>
							<li><a href="#">Watch <span class="pull-right">(4)</span></a></li>
							<li><a href="#">TV <span class="pull-right">(6)</span></a></li>
							<li><a href="#">Accessories <span class="pull-right">(38)</span></a></li>
						</ul>
					</div>
					<!-- END search-sidebar -->

</template>

<script>
export default {
  name: "ProductFilter",
  props: ["link", "text", "classList", "imgSrc"],
  data() {
    return {};
  },
};
</script>
