<template>
  <div>
    <!-- ================== BEGIN BASE CSS STYLE ================== -->
    <link
      href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700"
      rel="stylesheet"
    />
    <link href="../assets/css/blog/vendor.min.css" rel="stylesheet" />
    <link href="../assets/css/blog/app.min.css" rel="stylesheet" />
    <!-- ================== END core-css ================== -->

    <NavbarBlog></NavbarBlog>
    <PageTitle></PageTitle>

    <Post></Post>

    <FooterBlog></FooterBlog>
  </div>
</template>

<script>
import Post from "@/components/Post.vue";
import PageTitle from "@/components/PageTitle";
import NavbarBlog from "@/components/NavbarBlog.vue";
import FooterBlog from "@/components/FooterBlog.vue";

export default {
  components: { PageTitle, Post, FooterBlog, NavbarBlog },
  name: "PostDetailPage",

  watch: {
    $route: {
      handler: (to) => {
        document.title = to.meta.title;
      },
      immediate: true,
    },
  },
};
</script>
