<template>
  <!-- BEGIN promotion -->
  <div v-bind:class="classList">
    <div v-bind:class="classList2">
      <img v-bind:src="data.imgSrc" alt="" />
    </div>
    <div v-bind:class="classList3">
      <h4 class="promotion-title">{{ data.title }}</h4>
      <div class="promotion-price"><small>from</small> ${{ data.price }}</div>
      <p class="promotion-desc" v-html="data.description"></p>
      <a v-bind:href="data.link" class="promotion-btn">View More</a>
    </div>
  </div>
  <!-- END promotion -->
</template>

<script>
export default {
  name: "PromotionItem",
  props: ["classList", "classList2", "classList3", "data"],
  data() {
    return {};
  },
};
</script>
