<template>

     <!-- BEGIN header-logo -->
          <div class="header-logo">
            <router-link to="/">
              <span class="brand-logo"></span>
              <span class="brand-text">
                <span>Color</span>Admin
                <small>e-commerce frontend theme</small>
              </span>
            </router-link>
          </div>
          <!-- END header-logo -->

</template>


<script>

export default {
  name: "Logo",
  props: [""],
  data() {
    return {};
  },
};
</script>

