<template>
  <!-- BEGIN breadcrumb -->
  <ul v-bind:class="classList">
    <li v-for="(breadcrumb, i) in breadcrumbs" v-bind:key="i" v-bind:class="currentOrNot(i)">
      <a v-if="i + 1 !== breadcrumbs.length" v-bind:href="breadcrumb.link">{{ breadcrumb.name }}</a>
      <span v-else> {{ breadcrumb.name }}</span>
    </li>
    <!-- <li class="breadcrumb-item"><a href="#">Home</a></li>
    <li class="breadcrumb-item"><a href="#">Mobile Phone</a></li>
    <li class="breadcrumb-item"><a href="#">Apple</a></li>
    <li class="breadcrumb-item active">iPhone 6S Plus</li> -->
  </ul>
  <!-- END breadcrumb -->
</template>

<script>

export default {
  components: {  },
  name: "BreadCrumb",
  props: ["breadcrumbs", "classList"],
  data() {
    return {};
  },
  methods: {
    currentOrNot: function(i) {
      if (this.breadcrumbs.length === i + 1) {
        return "breadcrumb-item active";
      } else {
        return "breadcrumb-item";
      }
    },
  },
};
</script>
