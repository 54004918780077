<template> 
             <div class="post-li">
                <!-- begin post-content -->
                <div class="post-content">


                  <!-- begin post-image -->
                 <PostMedia v-bind:data="data" ></PostMedia>
                  <!-- end post-image -->


                  <!-- begin post-info -->
                  <div class="post-info">
                    <h4 class="post-title">
                      <router-link to="post_detail">{{data.title}}</router-link>
                    </h4>
                    <div class="post-by">
                      Posted By <a href="#">{{data.postByName}}</a> <span class="divider">|</span> {{data.postDate}}
                    </div>
                    <div class="post-desc">
                      {{data.postTextPreview}} [...]
                    </div>
                    <div class="read-btn-container">
                      <router-link v-bind:to="data.postLink"
                        >Read More <i class="fa fa-angle-double-right"></i
                      ></router-link>
                    </div>
                  </div>
                  <!-- end post-info -->
                </div>
                <!-- end post-content -->
              </div>


</template>

<script>
import PostMedia from "@/components/PostMedia";

export default {
  components: {  PostMedia},
  name: "PostGridItem",
  props: ["data"],
  data() {
    return {};
  },
};
</script>
