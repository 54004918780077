<template>
  <div>
    <!-- BEGIN #slider -->
    <div id="slider" class="section-container p-0 bg-black">
      <!-- BEGIN carousel -->
      <div id="main-carousel" class="carousel slide" data-ride="carousel">
        <!-- BEGIN carousel-inner -->
        <div class="carousel-inner">
          <!-- BEGIN item -->
          <SliderItem
          link="agile"
            backSrc="assets/img/slider/slider-1-cover.jpg"
            imgSrc="assets/img/slider/slider-1-product.png"
            title="iMac"
            subtitle="The vision is brighter than ever."
            price="2299.00"
            classList="carousel-item active"
            classList2="product-img right bottom fadeInRight animated"
            classList3="carousel-caption carousel-caption-left"
            classList4="title mb-5px fadeInLeftBig animated"
            classList5="mb-15px fadeInLeftBig animated"
            classList6="price mb-30px fadeInLeftBig animated"
          ></SliderItem>
          <!-- END item -->

          <!-- BEGIN item -->
          <SliderItem
          link="agile"
            backSrc="assets/img/slider/slider-2-cover.jpg"
            imgSrc="assets/img/slider/slider-2-product.png"
            title="iPhone X"
            subtitle="Say hello to the future."
            price="1,149.00"
            classList="carousel-item"
            classList2="product-img left bottom fadeInLeft animated"
            classList3="carousel-caption carousel-caption-right"
            classList4="title mb-5px fadeInRightBig animated"
            classList5="mb-15px fadeInRightBig animated"
            classList6="price mb-30px fadeInLeftBig animated"
          ></SliderItem>
          <!-- END item -->

          <!-- BEGIN item -->
          <SliderItem
            link="agile"
            backSrc="assets/img/slider/slider-3-cover.jpg"
            imgSrc=""
            title="Macbook Air"
            subtitle="Thin.Light.Powerful."
            price="999.00"
            classList="carousel-item"
            classList2=""
            classList3="carousel-caption"
            classList4="title mb-5px fadeInDownBig animated"
            classList5="mb-15px fadeInDownBig animated"
            classList6="price fadeInDownBig animated"
          ></SliderItem>
          <!-- END item -->
        </div>
        <!-- END carousel-inner -->
        <a class="carousel-control-prev" href="#main-carousel" data-bs-slide="prev">
          <i class="fa fa-angle-left"></i>
        </a>
        <a class="carousel-control-next" href="#main-carousel" data-bs-slide="next">
          <i class="fa fa-angle-right"></i>
        </a>
      </div>
      <!-- END carousel -->
    </div>
    <!-- END #slider -->
  </div>
</template>

<script>
import SliderItem from "@/components/SliderItem.vue";

export default {
  components: { SliderItem },
  name: "Slider",
  props: [""],
  data() {
    return {};
  },
};
</script>
