<template>
  <a v-bind:href="link" v-bind:class="classList"
    >{{ text }}<img v-if="imgSrc" v-bind:src="imgSrc" alt=""
  /></a>
</template>

<script>
export default {
  name: "LinkText",
  props: ["link", "text", "imgSrc", "classList"],
  data() {
    return {};
  },
};
</script>
