<template>
  <agile @after-change="e => currentSlide = e.currentSlide"
    :nav-buttons="false"
    :autoplay-speed="5000"
    :speed="2500"
    fade
    pause-on-hover
    pause-on-dots-hover
    autoplay
  >
    <div class="slide">
      {{ captions[currentSlide] }}
      <img
        src="https://images.unsplash.com/photo-1509549649946-f1b6276d4f35?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1600&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ"
        alt=""
      />
    </div>

    <div class="slide">
      {{ captions[currentSlide] }}
      <img
        src="https://images.unsplash.com/photo-1511469054436-c7dedf24c66b?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1600&fit=max&ixid=eyJhcHBfaWQiOjEyMDd9"
        alt=""
      />
    </div>

    <div class="slide">
      {{ captions[currentSlide] }}
      <img
        src="https://images.unsplash.com/photo-1511135232973-c3ee80040060?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1600&fit=max&ixid=eyJhcHBfaWQiOjEyMDd9"
        alt=""
      />
    </div>

    <div class="slide">
      {{ captions[currentSlide] }}
      <img
        src="https://images.unsplash.com/photo-1511231683436-44735d14c11c?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1600&fit=max&ixid=eyJhcHBfaWQiOjEyMDd9"
        alt=""
      />
    </div>

    <div class="slide">
      {{ captions[currentSlide] }}
      <img
        src="https://images.unsplash.com/photo-1517677129300-07b130802f46?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1600&fit=max&ixid=eyJhcHBfaWQiOjEyMDd9"
        alt=""
      />
    </div>
  </agile>
</template>

<script>
import {VueAgile} from "vue-agile";

export default {
  components: {

    agile: VueAgile,
  },
  name: "SliderAgile",
  props: ["v"],
  data() {
    return {
      currentSlide: 0,
      captions: [
        "This is slide 1",
        "This is the second slide",
        "This is a third slide",
        "This is a forth slide",
        "This is a fifth and final slide",
      ],
    
    };
  },
};
</script>


<style scoped>

.agile__dots {
  bottom: 10px;
  flex-direction: column;
  right: 30px;
  position: absolute;
}
.agile__dot {
  margin: 5px 0;
}
.agile__dot button {
  background-color: transparent;
  border: 1px solid #fff;
  cursor: pointer;
  display: block;
  height: 10px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  transition-duration: 0.3s;
  width: 10px;
}
.agile__dot--current button, .agile__dot:hover button {
  background-color: #fff;
}

.slide {
  display: block;
  height: 500px;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
}

</style>