<template>
  <!-- BEGIN search-content -->
  <div class="search-content">
    <!-- BEGIN search-toolbar -->
    <div class="search-toolbar">
      <!-- BEGIN row -->
      <div class="row">
        <div class="col-lg-6">
          <h4>We found 65 Items for "Apple Product"</h4>
        </div>
        <!-- END col-6 -->
        <!-- BEGIN col-6 -->
        <div class="col-lg-6 text-end">
          <ul class="sort-list">
            <li class="text"><i class="fa fa-filter"></i> Sort by:</li>
            <li class="active"><a href="#">Popular</a></li>
            <li><a href="#">New Arrival</a></li>
            <li><a href="#">Discount</a></li>
            <li><a href="#">Price</a></li>
          </ul>
        </div>
        <!-- END col-6 -->
      </div>
      <!-- END row -->
    </div>
    <!-- END search-toolbar -->

    <!-- BEGIN search-item-container -->
    <div class="search-item-container">
      <!-- BEGIN item-row -->
      <div class="item-row">
        <ProductItem v-for="(d, i) in row1" v-bind:key="i" v-bind:data="d"> </ProductItem>


        <!-- <ProductItem
          imgSrc="assets/img/product/product-iphone.png"
          title="iPhone 6s Plus<br />16GB"
          price="739.00"
          description="3D Touch. 12MP photos. 4K video."
          discount="15"
          disPrice="649.00"
          link="product_detail"
        ></ProductItem>

        <ProductItem
          imgSrc="assets/img/product/product-apple-tv.png"
          title="Apple TV<br />2016"
          price="799.00"
          description="The future of television is here."
          discount="32"
          disPrice="599.00"
          link="product_detail"
        ></ProductItem>

        <ProductItem
          imgSrc="assets/img/product/product-iphone-se.png"
          title="iPhone SE<br />32/64Gb"
          price="499.00"
          description="A big step for small."
          discount="20"
          disPrice="599.00"
          link="product_detail"
        ></ProductItem> -->

      </div>
      <!-- END item-row -->
      <!-- BEGIN item-row -->
      <div class="item-row">

         <ProductItem v-for="(d, i) in row2" v-bind:key="i" v-bind:data="d"> </ProductItem>


        <!-- <ProductItem
          imgSrc="assets/img/product/product-apple-watch.png"
          title="Apple Watch<br />‏Sport Edition"
          price="453.00"
          description="You. At a glance."
          discount="15"
          disPrice="399.00"
          link="product_detail"
        ></ProductItem>
  
        <ProductItem
          imgSrc="assets/img/product/product-imac.png"
          title="21.5-inch iMac<br />with Retina Display"
          price="799.00"
          description="Retina. Now in colossal and ginormous."
          discount="32"
          disPrice="599.00"
          link="product_detail"
        ></ProductItem>
  
        <ProductItem
          imgSrc="assets/img/product/product-macbook-pro.png"
          title="MacBook Pro<br />with Retina Display"
          price="199.00"
          description="Stunning Retina Display"
          discount="20"
          disPrice="99.00"
          link="product_detail"
        ></ProductItem> -->
     
     


      </div>
      <!-- END item-row -->
      <!-- BEGIN item-row -->
      <div class="item-row">
    
     <ProductItem v-for="(d, i) in row3" v-bind:key="i" v-bind:data="d"> </ProductItem>


        <!-- <ProductItem
          imgSrc="assets/img/product/product-ipad-pro.png"
          title="9.7-inch iPad Pro<br />32GB"
          price="739.00"
          description="3D Touch. 12MP photos. 4K video."
          discount="15"
          disPrice="649.00"
          link="product_detail"
        ></ProductItem>
 
 
        <ProductItem
          imgSrc="assets/img/product/product-mac-pro.png"
          title="Mac Pro<br />Quad-Core and Dual GPU"
          price="2599.00"
          description="Built for creativity on an epic scale."
          discount="32"
          disPrice="2999.00"
          link="product_detail"
        ></ProductItem>
     
     
        <ProductItem
          imgSrc="assets/img/product/product-ipad-air.png"
          title="iPad Air 2<br />32/64Gb"
          price="459.00"
          description="Light. Heavyweight."
          discount="20"
          disPrice="399.00"
          link="product_detail"
        ></ProductItem> -->


      </div>
      <!-- END item-row -->
    </div>
    <!-- END search-item-container -->

    <Pagination v-bind:data="pagesInfo"></Pagination>
  </div>
  <!-- END search-content -->
</template>

<script>
import ProductItem from "@/components/ProductItem.vue";
import Pagination from "@/components/Pagination.vue";

export default {
  components: { ProductItem, Pagination },
  name: "ProductResults",
  props: ["link", "text", "classList", "imgSrc"],
  computed:{
    // pages: function (){
    //   return 5
    // }
  },
  data() {
    return {
      pagesInfo: {
        pages: 5,
        currentPage: 1, 
      },

      row1: [
        {
          imgSrc: "assets/img/product/product-iphone.png",
          title: "iPhone 6s Plus<br />16GB",
          price: "739.00",
          description: "3D Touch. 12MP photos. 4K video.",
          discount: "15",
          disPrice: "649.00",
          link: "product_detail",
        },
        {
          imgSrc: "assets/img/product/product-apple-tv.png",
          title: "Apple TV<br />2016",
          price: "799.00",
          description: "The future of television is here.",
          discount: "32",
          disPrice: "599.00",
          link: "product_detail",
        },
        {
          imgSrc: "assets/img/product/product-iphone-se.png",
          title: "iPhone SE<br />32/64Gb",
          price: "499.00",
          description: "A big step for small.",
          discount: "20",
          disPrice: "599.00",
          link: "product_detail",
        },
      ],
      row2: [
        {
          imgSrc: "assets/img/product/product-apple-watch.png",
          title: "Apple Watch<br />‏Sport Edition",
          price: "453.00",
          description: "You. At a glance.",
          discount: "15",
          disPrice: "399.00",
          link: "product_detail",
        },
        {
          imgSrc: "assets/img/product/product-imac.png",
          title: "21.5-inch iMac<br />with Retina Display",
          price: "799.00",
          description: "Retina. Now in colossal and ginormous.",
          discount: "32",
          disPrice: "599.00",
          link: "product_detail",
        },
        {
          imgSrc: "assets/img/product/product-macbook-pro.png",
          title: "MacBook Pro<br />with Retina Display",
          price: "199.00",
          description: "Stunning Retina Display",
          discount: "20",
          disPrice: "99.00",
          link: "product_detail",
        },
      ],
      row3: [
        {
          imgSrc: "assets/img/product/product-ipad-pro.png",
          title: "9.7-inch iPad Pro<br />32GB",
          price: "739.00",
          description: "3D Touch. 12MP photos. 4K video.",
          discount: "15",
          disPrice: "649.00",
          link: "product_detail",
        },
        {
          imgSrc: "assets/img/product/product-mac-pro.png",
          title: "Mac Pro<br />Quad-Core and Dual GPU",
          price: "2599.00",
          description: "Built for creativity on an epic scale.",
          discount: "32",
          disPrice: "2999.00",
          link: "product_detail",
        },
        {
          imgSrc: "assets/img/product/product-ipad-air.png",
          title: "iPad Air 2<br />32/64Gb",
          price: "459.00",
          description: "Light. Heavyweight.",
          discount: "20",
          disPrice: "399.00",
          link: "product_detail",
        },
      ],
    };
  },
};
</script>
