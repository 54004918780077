<template>
  <!-- BEGIN item -->
  <div
    v-bind:class="classList"
    data-paroller="true"
    data-paroller-factor="0.3"
    data-paroller-factor-sm="0.01"
    data-paroller-factor-xs="0.01"
    v-bind:style="'background: url(' + backSrc + ') center 0 / cover no-repeat;'"
  >
    <div class="container">
      <img v-bind:src="imgSrc" v-bind:class="classList2" alt="" />
    </div>
    <div v-bind:class="classList3">
      <div class="container">
        <h3 v-bind:class="classList4">{{ title }}</h3>
        <p v-bind:class="classList5">{{ subtitle }}</p>
        <div v-bind:class="classList6">
          <small>from</small> <span>${{ price }}</span>
        </div>

        <LinkText
          v-bind:link="link"
          text="Buy Now"
          classList="btn btn-outline btn-lg fadeInLeftBig animated"
        ></LinkText>
      </div>
    </div>
  </div>
  <!-- END item -->
</template>

<script>
import LinkText from "@/components/LinkText.vue";

export default {
  components: { LinkText },
  name: "SliderItem",
  props: [
    "link",
    "backSrc",
    "imgSrc",
    "title",
    "subtitle",
    "price",
    "classList",
    "classList2",
    "classList3",
    "classList4",
    "classList5",
    "classList6",
  ],
  data() {
    return {};
  },
};
</script>
