<template>
 
 <div>
    <!-- begin post-video -->
      <div v-if="data.postVideoLink" class="post-video">
        <div class="ratio ratio-16x9">
          <iframe v-bind:src="data.postVideoLink" allowfullscreen></iframe>
        </div>
      </div>
      <!-- end post-video -->

      <!-- begin post-image -->
      <div v-if="data.postPicSrc" class="post-image post-image-with-carousel">
        <!-- begin carousel -->
        <div id="carousel-post" class="carousel slide" data-ride="carousel">
          <!-- begin carousel-indicators -->
          <ol class="carousel-indicators">
            <li data-target="#carousel-post" data-slide-to="0" class="active"></li>
            <li data-target="#carousel-post" data-slide-to="1"></li>
            <li data-target="#carousel-post" data-slide-to="2"></li>
          </ol>
          <!-- end carousel-indicators -->
          <!-- begin carousel-inner -->
          <div class="carousel-inner">
            <div class="carousel-item active">
              <router-link to="post_detail"
                ><img class="d-block w-100" v-bind:src="data.postPicSrc" alt=""
              /></router-link>
            </div>
            <div class="carousel-item">
              <router-link to="post_detail"
                ><img class="d-block w-100" src="assets/img/post/post-2.jpg" alt=""
              /></router-link>
            </div>
            <div class="carousel-item">
              <router-link to="post_detail"
                ><img class="d-block w-100" src="assets/img/post/post-3.jpg" alt=""
              /></router-link>
            </div>
          </div>
          <!-- end carousel-inner -->
          <!-- begin carousel-control -->
          <a class="carousel-control-prev" href="#carousel-post" role="button" data-slide="prev">
            <span class="fa fa-chevron-left" aria-hidden="true"></span>
          </a>
          <a class="carousel-control-next" href="#carousel-post" role="button" data-slide="next">
            <span class="fa fa-chevron-right" aria-hidden="true"></span>
          </a>
          <!-- end carousel-control -->
        </div>
        <!-- end carousel -->
      </div>
      <!-- end post-image -->

      <!-- begin blockquote -->
      <blockquote v-if="data.postQuote">
        {{ data.postQuote }}
      </blockquote>
      <!-- end blockquote -->

 </div>
 
</template>

<script>

export default {
  components: {  },
  name: "PostMedia",
  props: [
    "data",
  ],
  data() {
    return {};
  },
};
</script>
