<template>
   <!-- begin post-list -->
            <div class="post-list post-grid post-grid-2">
              <PostGridItem
                v-for="(d, i) in data"
              v-bind:key="i"

              v-bind:data="d"


              ></PostGridItem>
 
            </div>
            <!-- end post-list -->
  

</template>

<script>
import PostGridItem from "@/components/PostGridItem";

export default {
  components: {  PostGridItem },
  name: "PostGrid",
  props: ["data"],
  data() {
    return {};
  },
};
</script>
