<template>
  <li>
    <div class="info">
      <h4 class="title">
        <a v-bind:href="link">{{ text }}</a>
      </h4>
      <div class="date">{{ pubDate }}</div>
    </div>
  </li>
</template>

<script>
export default {
  name: "LinkRecentPost",
  props: ["link", "text", "pubDate"],
  data() {
    return {};
  },
};
</script>
