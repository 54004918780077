<template>
  <!-- begin section-container -->
  <div class="section-container">
    <h4 class="section-title">
      <span>{{ data.title }}</span>
    </h4>
    <ul class="sidebar-list">
      <SidebarCategoryLink
        v-for="(d, i) in data.links"
        v-bind:key="i"
        v-bind:link="d.link"
        v-bind:text="d.text"
        v-bind:count="d.count"
      ></SidebarCategoryLink>

      <!-- <SidebarCategoryLink link="#" text="Sports" count="20"></SidebarCategoryLink>
      <SidebarCategoryLink link="#" text="Outdoor Sports" count="45"></SidebarCategoryLink>
      <SidebarCategoryLink link="#" text="Indoor Sports" count="1,292"></SidebarCategoryLink>
      <SidebarCategoryLink link="#" text="Video Shooting" count="12"></SidebarCategoryLink>
      <SidebarCategoryLink link="#" text="Drone" count="229"></SidebarCategoryLink>
      <SidebarCategoryLink link="#" text="Uncategorized" count="1,482"></SidebarCategoryLink> -->
    </ul>
  </div>
  <!-- end section-container -->
</template>

<script>
import SidebarCategoryLink from "@/components/SidebarCategoryLink";

export default {
  components: {
    SidebarCategoryLink,
  },
  name: "SidebarCategorySection",
  props: ["link", "text", "count", "data"],
  data() {
    return {};
  },
};
</script>
