<template>
  <div>
    <!-- BEGIN #top-nav -->
    <div id="top-nav" class="top-nav">
      <!-- BEGIN container -->
      <div class="container">
        <div class="collapse navbar-collapse">
          <ul class="nav navbar-nav">
            <li class="dropdown dropdown-hover">
              <a href="#" data-bs-toggle="dropdown"
                ><img src="assets/img/flag/flag-english.png" class="flag-img" alt=""/> English
                <b class="caret"></b
              ></a>
              <ul class="dropdown-menu">
                <LangFlag
                  link="about"
                  imgSrc="assets/img/flag/flag-english.png"
                  text="English"
                ></LangFlag>
                <LangFlag
                  link="about"
                  imgSrc="assets/img/flag/flag-german.png"
                  text="German"
                ></LangFlag>
                <LangFlag
                  link="about"
                  imgSrc="assets/img/flag/flag-spanish.png"
                  text="Spanish"
                ></LangFlag>
                <LangFlag
                  link="about"
                  imgSrc="assets/img/flag/flag-french.png"
                  text="French"
                ></LangFlag>
                <LangFlag
                  link="about"
                  imgSrc="assets/img/flag/flag-chinese.png"
                  text="Chinese"
                ></LangFlag>
              </ul>
            </li>
            <li><a href="about">Customer Care</a></li>
            <li><a href="about">Order Tracker</a></li>
          </ul>
          <ul class="nav navbar-nav navbar-end">
            <LinkTextList link="about" text="Career"></LinkTextList>
            <LinkTextList link="about" text="Our Forum"></LinkTextList>
            <LinkTextList link="about" text="Newsletter"></LinkTextList>

            <SocialIconList link="about" classList="fab fa-facebook-f f-s-14"></SocialIconList>
            <SocialIconList link="about" classList="fab fa-twitter f-s-14"></SocialIconList>
            <SocialIconList link="about" classList="fab fa-instagram f-s-14"></SocialIconList>
            <SocialIconList link="about" classList="fab fa-dribbble f-s-14"></SocialIconList>
            <SocialIconList link="about" classList="fab fa-google f-s-14"></SocialIconList>
          </ul>
        </div>
      </div>
      <!-- END container -->
    </div>
    <!-- END #top-nav -->
  </div>
</template>

<script>
import LangFlag from "@/components/LangFlag";
import LinkTextList from "@/components/LinkTextList";
import SocialIconList from "@/components/SocialIconList";

export default {
  components: { LangFlag, LinkTextList, SocialIconList },
  name: "Navtop",
  props: [],
  data() {
    return {};
  },
};
</script>
