<template>
  <!-- BEGIN col-2 -->
  <div class="col-lg-2 col-md-4 col-sm-6">
    <!-- BEGIN item -->
    <div class="item item-thumbnail">
      <router-link v-bind:to="data.link" class="item-image">
        <img v-bind:src="data.imgSrc" alt="" />
        <div class="discount">{{ data.discount }}% OFF</div>
      </router-link>
      <div class="item-info">
        <h4 class="item-title">
          <router-link v-bind:to="data.link" v-html="data.title"></router-link>
        </h4>
        <p class="item-desc">{{ data.description }}</p>
        <div class="item-price">${{ data.disPrice }}</div>
        <div class="item-discount-price">${{ data.price }}</div>
      </div>
    </div>
    <!-- END item -->
  </div>
  <!-- BEGIN col-2 -->
</template>

<script>
export default {
  name: "TrendingItem",
  props: ["data"],
  data() {
    return {};
  },
};
</script>
