<template>
  <div>
    <!-- BEGIN #tablet-list -->
    <div id="tablet-list" class="section-container pt-0">
      <!-- BEGIN container -->
      <div class="container">
        <!-- BEGIN section-title -->
        <h4 class="section-title clearfix">
          <span class="flex-1">
            Tablet
            <small>Shop and get your favourite tablet at amazing prices!</small>
          </span>
          <a href="#" class="btn">SHOW ALL</a>
        </h4>
        <!-- END section-title -->
        <!-- BEGIN category-container -->
        <div class="category-container">
          <!-- BEGIN category-sidebar -->
          <div class="category-sidebar">
            <ul class="category-list">
              <li class="list-header">Top Categories</li>

              <LinkTextList
                v-for="(tab, index) in tabs"
                v-bind:key="index"
                v-bind:link="'#' + tab"
                v-bind:text="tab"
                v-on:click.native="changeTab(index)"
              ></LinkTextList>

              <!-- <LinkTextList link="#" text="Apple"></LinkTextList>
              <LinkTextList link="#" text="HP"></LinkTextList>
              <LinkTextList link="#" text="Huawei"></LinkTextList>
              <LinkTextList link="#" text="Samsung"></LinkTextList>
              <LinkTextList link="#" text="Sony"></LinkTextList>
              <LinkTextList link="#" text="All Brands"></LinkTextList> -->
            </ul>
          </div>
          <!-- END category-sidebar -->
          <!-- BEGIN category-detail -->

          <!-- <div v-for="(tab, index) in tabs" v-bind:key="index" -->
          <div class="category-detail">
            <!-- <span v-if="activeTab == index"> -->
            <!-- {{ tab }} -->
            <!-- BEGIN category-item -->
            <a href="#" class="category-item full">
              <div class="item">
                <div class="item-cover">
                  <img src="assets/img/product/product-huawei-mediapad.jpg" alt="" />
                </div>
                <div class="item-info bottom">
                  <h4 class="item-title">Huawei MediaPad T1 7.0</h4>
                  <p class="item-desc">Vibrant colors. Beautifully displayed</p>
                  <div class="item-price">$299.00</div>
                </div>
              </div>
            </a>
            <!-- END category-item -->
            <!-- BEGIN category-item -->
            <div class="category-item list">
              <!-- BEGIN item-row -->
              <div class="item-row">
                <ProductItem
                  v-for="(d, i) in itemsRow1Computed"
                  v-bind:key="i"
                  v-bind:data="d"
                ></ProductItem>

                <!-- <ProductItem
                  imgSrc="assets/img/product/product-ipad-pro.png"
                  title="9.7-inch iPad Pro<br />32GB"
                  price="739.00"
                  description="3D Touch. 12MP photos. 4K video."
                  discount="15"
                  disPrice="649.00"
                  link="product_detail"
                ></ProductItem>

                <ProductItem
                  imgSrc="assets/img/product/product-galaxy-tab2.png"
                  title="Samsung Galaxy Tab S2<br />Black"
                  price="499.00"
                  description="A Brilliant Screen That Adjusts to You"
                  discount="32"
                  disPrice="399.00"
                  link="product_detail"
                ></ProductItem>

                <ProductItem
                  imgSrc="assets/img/product/product-galaxy-taba.png"
                  title='Samsung Galaxy Tab A<br />9.7" 16Gb(Wi-Fi)'
                  price="399.00"
                  description="Keep All Your Samsung Devices In Sync"
                  discount="20"
                  disPrice="349.00"
                  link="product_detail"
                ></ProductItem> -->
              </div>
              <!-- END item-row -->
              <!-- BEGIN item-row -->
              <div class="item-row">
                <ProductItem
                  v-for="(d, i) in itemsRow2Computed"
                  v-bind:key="i"
                  v-bind:data="d"
                ></ProductItem>

                <!-- <ProductItem
                  imgSrc="assets/img/product/product-hp-spectrex2.png"
                  title="HP Spectre x2<br />‏12-a011nr"
                  price="850.00"
                  description="Our thinnest detachable separates from all others."
                  discount="15"
                  disPrice="799.00"
                  link="product_detail"
                ></ProductItem>

                <ProductItem
                  imgSrc="assets/img/product/product-xperia-z2.png"
                  title="Sony Xperia Z2 Tablet<br />Black Color"
                  price="259.00"
                  description="For unexpectedly beautiful moments"
                  discount="32"
                  disPrice="199.00"
                  link="product_detail"
                ></ProductItem>
   
                <ProductItem
                  imgSrc="assets/img/product/product-ipad-air.png"
                  title="iPad Air 2<br />32/64Gb"
                  price="459.00"
                  description="Light. Heavyweight."
                  discount="20"
                  disPrice="399.00"
                  link="product_detail"
                ></ProductItem> -->
              </div>
              <!-- END item-row -->
            </div>
            <!-- END category-item -->
            <!-- </span> -->
          </div>

          <!-- END category-detail -->
        </div>
        <!-- END category-container -->
      </div>
      <!-- END container -->
    </div>
    <!-- END #tablet-list -->
  </div>
</template>

<script>
import LinkTextList from "@/components/LinkTextList.vue";
import ProductItem from "@/components/ProductItem.vue";

export default {
  components: { LinkTextList, ProductItem },
  name: "Tablet",
  props: [""],
  data() {
    return {
      activeTab: 0,
      tabs: ["Apple", "HP", "Huawei", "Samsung", "Sony", "All Brands"],

      items: {
        Apple: [
          {
            imgSrc: "assets/img/product/product-ipad-pro.png",
            title: "Apple 9.7-inch iPad Pro<br />32GB",
            price: "739.00",
            description: "3D Touch. 12MP photos. 4K video.",
            discount: "15",
            disPrice: "649.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-galaxy-tab2.png",
            title: "Samsung Galaxy Tab S2<br />Black",
            price: "499.00",
            description: "A Brilliant Screen That Adjusts to You",
            discount: "32",
            disPrice: "399.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-galaxy-taba.png",
            title: 'Samsung Galaxy Tab A<br />9.7" 16Gb(Wi-Fi)',
            price: "399.00",
            description: "Keep All Your Samsung Devices In Sync",
            discount: "20",
            disPrice: "349.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-hp-spectrex2.png",
            title: "HP Spectre x2<br />‏12-a011nr",
            price: "850.00",
            description: "Our thinnest detachable separates from all others.",
            discount: "15",
            disPrice: "799.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-xperia-z2.png",
            title: "Sony Xperia Z2 Tablet<br />Black Color",
            price: "259.00",
            description: "For unexpectedly beautiful moments",
            discount: "32",
            disPrice: "199.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-ipad-air.png",
            title: "iPad Air 2<br />32/64Gb",
            price: "459.00",
            description: "Light. Heavyweight.",
            discount: "20",
            disPrice: "399.00",
            link: "product_detail",
          },
        ],

        HP: [
          {
            imgSrc: "assets/img/product/product-ipad-pro.png",
            title: "HP 9.7-inch iPad Pro<br />32GB",
            price: "739.00",
            description: "3D Touch. 12MP photos. 4K video.",
            discount: "15",
            disPrice: "649.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-galaxy-tab2.png",
            title: "Samsung Galaxy Tab S2<br />Black",
            price: "499.00",
            description: "A Brilliant Screen That Adjusts to You",
            discount: "32",
            disPrice: "399.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-galaxy-taba.png",
            title: 'Samsung Galaxy Tab A<br />9.7" 16Gb(Wi-Fi)',
            price: "399.00",
            description: "Keep All Your Samsung Devices In Sync",
            discount: "20",
            disPrice: "349.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-hp-spectrex2.png",
            title: "HP Spectre x2<br />‏12-a011nr",
            price: "850.00",
            description: "Our thinnest detachable separates from all others.",
            discount: "15",
            disPrice: "799.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-xperia-z2.png",
            title: "Sony Xperia Z2 Tablet<br />Black Color",
            price: "259.00",
            description: "For unexpectedly beautiful moments",
            discount: "32",
            disPrice: "199.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-ipad-air.png",
            title: "iPad Air 2<br />32/64Gb",
            price: "459.00",
            description: "Light. Heavyweight.",
            discount: "20",
            disPrice: "399.00",
            link: "product_detail",
          },
        ],
        Huawei: [
          {
            imgSrc: "assets/img/product/product-ipad-pro.png",
            title: "Huawei 9.7-inch iPad Pro<br />32GB",
            price: "739.00",
            description: "3D Touch. 12MP photos. 4K video.",
            discount: "15",
            disPrice: "649.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-galaxy-tab2.png",
            title: "Samsung Galaxy Tab S2<br />Black",
            price: "499.00",
            description: "A Brilliant Screen That Adjusts to You",
            discount: "32",
            disPrice: "399.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-galaxy-taba.png",
            title: 'Samsung Galaxy Tab A<br />9.7" 16Gb(Wi-Fi)',
            price: "399.00",
            description: "Keep All Your Samsung Devices In Sync",
            discount: "20",
            disPrice: "349.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-hp-spectrex2.png",
            title: "HP Spectre x2<br />‏12-a011nr",
            price: "850.00",
            description: "Our thinnest detachable separates from all others.",
            discount: "15",
            disPrice: "799.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-xperia-z2.png",
            title: "Sony Xperia Z2 Tablet<br />Black Color",
            price: "259.00",
            description: "For unexpectedly beautiful moments",
            discount: "32",
            disPrice: "199.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-ipad-air.png",
            title: "iPad Air 2<br />32/64Gb",
            price: "459.00",
            description: "Light. Heavyweight.",
            discount: "20",
            disPrice: "399.00",
            link: "product_detail",
          },
        ],
        Samsung: [
          {
            imgSrc: "assets/img/product/product-ipad-pro.png",
            title: "Samsung 9.7-inch iPad Pro<br />32GB",
            price: "739.00",
            description: "3D Touch. 12MP photos. 4K video.",
            discount: "15",
            disPrice: "649.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-galaxy-tab2.png",
            title: "Samsung Galaxy Tab S2<br />Black",
            price: "499.00",
            description: "A Brilliant Screen That Adjusts to You",
            discount: "32",
            disPrice: "399.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-galaxy-taba.png",
            title: 'Samsung Galaxy Tab A<br />9.7" 16Gb(Wi-Fi)',
            price: "399.00",
            description: "Keep All Your Samsung Devices In Sync",
            discount: "20",
            disPrice: "349.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-hp-spectrex2.png",
            title: "HP Spectre x2<br />‏12-a011nr",
            price: "850.00",
            description: "Our thinnest detachable separates from all others.",
            discount: "15",
            disPrice: "799.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-xperia-z2.png",
            title: "Sony Xperia Z2 Tablet<br />Black Color",
            price: "259.00",
            description: "For unexpectedly beautiful moments",
            discount: "32",
            disPrice: "199.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-ipad-air.png",
            title: "iPad Air 2<br />32/64Gb",
            price: "459.00",
            description: "Light. Heavyweight.",
            discount: "20",
            disPrice: "399.00",
            link: "product_detail",
          },
        ],
        Sony: [
          {
            imgSrc: "assets/img/product/product-ipad-pro.png",
            title: "Sony 9.7-inch iPad Pro<br />32GB",
            price: "739.00",
            description: "3D Touch. 12MP photos. 4K video.",
            discount: "15",
            disPrice: "649.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-galaxy-tab2.png",
            title: "Samsung Galaxy Tab S2<br />Black",
            price: "499.00",
            description: "A Brilliant Screen That Adjusts to You",
            discount: "32",
            disPrice: "399.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-galaxy-taba.png",
            title: 'Samsung Galaxy Tab A<br />9.7" 16Gb(Wi-Fi)',
            price: "399.00",
            description: "Keep All Your Samsung Devices In Sync",
            discount: "20",
            disPrice: "349.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-hp-spectrex2.png",
            title: "HP Spectre x2<br />‏12-a011nr",
            price: "850.00",
            description: "Our thinnest detachable separates from all others.",
            discount: "15",
            disPrice: "799.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-xperia-z2.png",
            title: "Sony Xperia Z2 Tablet<br />Black Color",
            price: "259.00",
            description: "For unexpectedly beautiful moments",
            discount: "32",
            disPrice: "199.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-ipad-air.png",
            title: "iPad Air 2<br />32/64Gb",
            price: "459.00",
            description: "Light. Heavyweight.",
            discount: "20",
            disPrice: "399.00",
            link: "product_detail",
          },
        ],
        "All Brands": [
          {
            imgSrc: "assets/img/product/product-ipad-pro.png",
            title: "All Brands 9.7-inch iPad Pro<br />32GB",
            price: "739.00",
            description: "3D Touch. 12MP photos. 4K video.",
            discount: "15",
            disPrice: "649.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-galaxy-tab2.png",
            title: "Samsung Galaxy Tab S2<br />Black",
            price: "499.00",
            description: "A Brilliant Screen That Adjusts to You",
            discount: "32",
            disPrice: "399.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-galaxy-taba.png",
            title: 'Samsung Galaxy Tab A<br />9.7" 16Gb(Wi-Fi)',
            price: "399.00",
            description: "Keep All Your Samsung Devices In Sync",
            discount: "20",
            disPrice: "349.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-hp-spectrex2.png",
            title: "HP Spectre x2<br />‏12-a011nr",
            price: "850.00",
            description: "Our thinnest detachable separates from all others.",
            discount: "15",
            disPrice: "799.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-xperia-z2.png",
            title: "Sony Xperia Z2 Tablet<br />Black Color",
            price: "259.00",
            description: "For unexpectedly beautiful moments",
            discount: "32",
            disPrice: "199.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-ipad-air.png",
            title: "iPad Air 2<br />32/64Gb",
            price: "459.00",
            description: "Light. Heavyweight.",
            discount: "20",
            disPrice: "399.00",
            link: "product_detail",
          },
        ],
      },
    };
  },
  methods: {
    changeTab: function(activeTab) {
      this.activeTab = activeTab;
    },
  },
  computed: {
    itemsRow1Computed() {
      let rowItems = [];
      for (let index = 0; index < 3; index++) {
        rowItems.push(this.items[this.tabs[this.activeTab]][index]);
      }
      return rowItems;
    },
    itemsRow2Computed() {
      let rowItems = [];
      for (let index = 3; index < 6; index++) {
        rowItems.push(this.items[this.tabs[this.activeTab]][index]);
      }
      return rowItems;
    },
  },
};
</script>
