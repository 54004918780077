<template>
  <li>
    <a v-bind:href="link" class="dropdown-item"
      ><img v-bind:src="imgSrc" class="flag-img" alt="" /> {{ text }}</a
    >
  </li>
</template>

<script>
export default {
  name: "LangFlag",
  props: ["link", "text", "imgSrc"],
  data() {
    return {};
  },
};
</script>
