<template>
  <div>
    <!-- ================== BEGIN BASE CSS STYLE ================== -->
    <link
      href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700"
      rel="stylesheet"
    />
    <link href="../assets/css/blog/vendor.min.css" rel="stylesheet" />
    <link href="../assets/css/blog/app.min.css" rel="stylesheet" />
    <!-- ================== END core-css ================== -->

    <NavbarBlog></NavbarBlog>
    <PageTitle v-bind:data="PageTitle"></PageTitle>

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin container -->
      <div class="container">
        <!-- begin row -->
        <div class="row row-space-30">
          <!-- begin col-9 -->
          <div class="col-lg-9">
            <!-- begin post-list -->
            <PostGrid v-bind:data="PostGrid"> </PostGrid>
            <!-- end post-list -->

            <div class="section-container">
              <!-- begin pagination -->
              <div class="pagination-container">
                <ul class="pagination justify-content-center">
                  <li class="page-item disabled">
                    <a class="page-link" href="javascript:;">Prev</a>
                  </li>
                  <li class="page-item active"><a class="page-link" href="javascript:;">1</a></li>
                  <li class="page-item"><a class="page-link" href="javascript:;">2</a></li>
                  <li class="page-item"><a class="page-link" href="javascript:;">3</a></li>
                  <li class="page-item"><span class="text">...</span></li>
                  <li class="page-item"><a class="page-link" href="javascript:;">1797</a></li>
                  <li class="page-item"><a class="page-link" href="javascript:;">Next</a></li>
                </ul>
              </div>
              <!-- end pagination -->
            </div>
          </div>
          <!-- end col-9 -->
          <!-- begin col-3 -->
          <div class="col-lg-3">
            <!-- begin section-container -->
            <div class="section-container">
              <div class="input-group sidebar-search">
                <input type="text" class="form-control" placeholder="Search Our Stories..." />
                <button class="btn btn-inverse" type="button"><i class="fa fa-search"></i></button>
              </div>
            </div>
            <!-- end section-container -->
            <!-- begin section-container -->
            <div class="section-container">
              <h4 class="section-title"><span>Categories</span></h4>
              <ul class="sidebar-list">
                <li><a href="#">Sports (20)</a></li>
                <li><a href="#">Outdoor Sports (45)</a></li>
                <li><a href="#">Indoor Sports (1,292)</a></li>
                <li><a href="#">Video Shooting (12)</a></li>
                <li><a href="#">Drone (229)</a></li>
                <li><a href="#">Uncategorized (1,482)</a></li>
              </ul>
            </div>
            <!-- end section-container -->
            <!-- begin section-container -->
            <div class="section-container">
              <h4 class="section-title"><span>Recent Post</span></h4>
              <ul class="sidebar-recent-post">
                <li>
                  <div class="info">
                    <h4 class="title"><a href="#">Lorem ipsum dolor sit amet.</a></h4>
                    <div class="date">23 December 2018</div>
                  </div>
                </li>
                <li>
                  <div class="info">
                    <h4 class="title"><a href="#">Vestibulum a cursus arcu.</a></h4>
                    <div class="date">16 December 2018</div>
                  </div>
                </li>
                <li>
                  <div class="info">
                    <h4 class="title"><a href="#">Nullam vel condimentum lectus. </a></h4>
                    <div class="date">7 December 2018</div>
                  </div>
                </li>
                <li>
                  <div class="info">
                    <h4 class="title">
                      <a href="#">Proin in dui egestas libero posuere ullamcorper. </a>
                    </h4>
                    <div class="date">20 November 2018</div>
                  </div>
                </li>
                <li>
                  <div class="info">
                    <h4 class="title"><a href="#">Interdum et malesuada fames ac ante.</a></h4>
                    <div class="date">5 November 2018</div>
                  </div>
                </li>
              </ul>
            </div>
            <!-- end section-container -->
            <!-- begin section-container -->
            <div class="section-container">
              <h4 class="section-title"><span>Follow Us</span></h4>
              <ul class="sidebar-social-list">
                <li>
                  <a href="#"><i class="fab fa-facebook"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fab fa-twitter"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fab fa-google-plus"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fab fa-instagram"></i></a>
                </li>
              </ul>
            </div>
            <!-- end section-container -->
          </div>
          <!-- end col-3 -->
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end #content -->

    <FooterBlog></FooterBlog>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle";
import NavbarBlog from "@/components/NavbarBlog.vue";
import FooterBlog from "@/components/FooterBlog.vue";
import PostGrid from "@/components/PostGrid.vue";

export default {
  components: { PageTitle, NavbarBlog, FooterBlog, PostGrid },
  name: "PostGridPage",
  data() {
    return {
      PageTitle:{
        style:"background: url(assets/img/cover/cover-9.jpg) center 0px / cover no-repeat",
        title: "Official Color Admin Blog",
        subtitle: "Blog Concept Front End Page"
      },
      PostGrid: [
        {
          title: "Bootstrap Carousel Blog Post",
          postByName: "admin",
          postDate: "03 Sep 2018",
          postTextPreview:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis elit dolor, elementum ut ligula ultricies, aliquet eleifend risus. Vivamus ut auctor sapien",
          postLink: "post_detail",
          postPicSrc: "assets/img/post/post-1.jpg",
        },

        {
          title: "Demonstration Blog Post",
          postByName: "admin",
          postDate: "21 Oct 2018",
          postTextPreview:
            "Pellentesque sit amet lectus at urna tempus tincidunt. Curabitur aliquet nisl ut magna efficitur scelerisque. Mauris molestie elementum massa eget bibendum. Sed mauris tortor, condimentum nec efficitur lobortis, tempus ac metus. Donec molestie, tortor ut rhoncus consectetur, ipsum elit maximus nulla, a vulputate augue massa ac dolor. Quisque euismod ornare cursus. Ut consequat pellentesque mattis",
          postLink: "post_detail",

          postPicSrc: "assets/img/post/post-4.jpg",
        },
        {
          title: "Blog Post Video",
          postByName: "admin",
          postDate: "Oct 18, 2018",
          postTextPreview:
            "Praesent maximus malesuada purus, sit amet auctor velit scelerisque nec. Suspendisse eget pellentesque dui, ut egestas orci. Proin eget massa et magna faucibus pulvinar. Quisque tortor orci, volutpat vel auctor non, varius a augue. Cras non ante arcu. Phasellus sit amet dolor non est dictum convallis vel eu lectus. Etiam consectetur non leo at auctor. Proin porttitor tellus arcu, in accumsan eros tincidunt eget",
          postLink: "post_detail",
          postVideoLink: "https://www.youtube.com/embed/_5aKcpAhTOk",
        },
        {
          title: "Blockquote Post",
          postByName: "admin",
          postDate: "12 Oct 2018",
          postTextPreview:
            "Ut vulputate sem id egestas faucibus. Phasellus volutpat malesuada lacus, eu semper enim hendrerit a. Mauris vehicula sapien sit amet eros pharetra dignissim. Quisque sed elit hendrerit, tempor sem ut, faucibus massa. Aliquam rutrum id massa interdum dapibus",
          postLink: "post_detail",
          postQuote:
            "What is design? It's where you stand with a foot in two worlds - the world of technology and the world of people and human purposes - and you try to bring the two together.",
        },
      ],
    };
  },
  watch: {
    $route: {
      handler: (to) => {
        document.title = to.meta.title;
      },
      immediate: true,
    },
  },
};
</script>
