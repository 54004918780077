<template>
  <!-- BEGIN pagination -->
  <ul class="pagination justify-content-center mt-0">
    <!-- <li class="page-item disabled"><a href="javascript:;" class="page-link">Previous</a></li> -->

    <!-- <LinkTextList v-for="(d, i) in pages" v-bind:key="i"
	 v-bind:data="d" 
	 v-bind:class="'page-link'"
	></LinkTextList> -->

    <li v-for="(d, i) in pages" v-bind:key="i" v-bind:class="d.classList">
      <a class="page-link" v-bind:href="d.link">{{ d.text }}</a>
    </li>

    <!-- <li class="page-item active"><a class="page-link" href="javascript:;">1</a></li>
							<li class="page-item"><a class="page-link" href="javascript:;">2</a></li>
							<li class="page-item"><a class="page-link" href="javascript:;">3</a></li>
							<li class="page-item"><a class="page-link" href="javascript:;">4</a></li>
							<li class="page-item"><a class="page-link" href="javascript:;">5</a></li> -->
    <!-- <li class="page-item"><a class="page-link" href="javascript:;">Next</a></li> -->
  </ul>
  <!-- END pagination -->
</template>

<script>

export default {
  components: {  },
  name: "Pagination",
  props: ["title", "text", "classList", "data"],
  computed: {
    pages: function() {
      let pages = [];
      let i;

      if (this.data.currentPage == 1) {
        pages.push({
          text: "Prev",
          classList: "page-item disabled",
          link: "product_page" + (this.data.currentPage - 1),
        });
      } else {
        pages.push({
          text: "Prev",
          classList: "page-item",
          link: "product_page" + (this.data.currentPage - 1),
        });
      }

      for (i = 1; i <= this.data.pages; i++) {
        if (i == this.data.currentPage) {
          pages.push({ text: i, classList: "page-item active", link: "product_page" + i });
        } else {
          pages.push({ text: i, classList: "page-item", link: "product_page" + i });
        }
      }

      pages.push({
        text: "Next",
        classList: "page-item",
        link: "product_page" + (this.data.currentPage + 1),
      });


      return pages;
    },
  },
  data() {
    return {
      // pages:[]
    };
  },
};
</script>
