<template>
  <div>
    <!-- BEGIN #footer -->
    <div id="footer" class="footer">
      <!-- BEGIN container -->
      <div class="container">
        <!-- BEGIN row -->
        <div class="row">
          <!-- BEGIN col-3 -->
          <div class="col-lg-3">
            <h4 class="footer-header">ABOUT US</h4>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec tristique dolor, ac
              efficitur velit. Nulla lobortis tempus convallis. Nulla aliquam lectus eu porta
              pulvinar. Mauris semper justo erat.
            </p>
            <p class="mb-lg-4 mb-0">
              Vestibulum porttitor lorem et vestibulum pharetra. Phasellus sit amet mi congue,
              hendrerit mi ut, dignissim eros.
            </p>
          </div>
          <!-- END col-3 -->
          <!-- BEGIN col-3 -->
          <div class="col-lg-3">
            <h4 class="footer-header">RELATED LINKS</h4>
            <ul class="fa-ul mb-lg-4 mb-0 p-0">
              <LinkTextMenu
                link="about"
                text="Shopping Help"
                classList="fa fa-fw fa-angle-right"
              ></LinkTextMenu>
              <LinkTextMenu
                link="about"
                text="Terms of Use"
                classList="fa fa-fw fa-angle-right"
              ></LinkTextMenu>
              <LinkTextMenu
                link="about"
                text="Contact Us"
                classList="fa fa-fw fa-angle-right"
              ></LinkTextMenu>
              <LinkTextMenu
                link="about"
                text="Careers"
                classList="fa fa-fw fa-angle-right"
              ></LinkTextMenu>
              <LinkTextMenu
                link="about"
                text="Payment Method"
                classList="fa fa-fw fa-angle-right"
              ></LinkTextMenu>
              <LinkTextMenu
                link="about"
                text="Sales & Refund"
                classList="fa fa-fw fa-angle-right"
              ></LinkTextMenu>
              <LinkTextMenu
                link="about"
                text="Sitemap"
                classList="fa fa-fw fa-angle-right"
              ></LinkTextMenu>
              <LinkTextMenu
                link="about"
                text="Privacy & Policy"
                classList="fa fa-fw fa-angle-right"
              ></LinkTextMenu>
            </ul>
          </div>
          <!-- END col-3 -->
          <!-- BEGIN col-3 -->
          <div class="col-lg-3">
            <h4 class="footer-header">LATEST PRODUCT</h4>
            <ul class="list-unstyled list-product mb-lg-4 mb-0 p-0">
              <LatestProductLink
                imgSrc="assets/img/product/product-iphone-6s.jpg"
                title="Iphone 6s"
                price="1200.00"
              ></LatestProductLink>

              <LatestProductLink
                imgSrc="assets/img/product/product-galaxy-s6.jpg"
                title="Samsung Galaxy s7"
                price="850.00"
              ></LatestProductLink>

              <LatestProductLink
                imgSrc="assets/img/product/product-ipad-pro.jpg"
                title="Ipad Pro"
                price="800.00"
              ></LatestProductLink>

              <LatestProductLink
                imgSrc="assets/img/product/product-galaxy-note5.jpg"
                title="Samsung Galaxy Note 5"
                price="1200.00"
              ></LatestProductLink>
            </ul>
          </div>
          <!-- END col-3 -->
          <!-- BEGIN col-3 -->
          <div class="col-lg-3">
            <h4 class="footer-header">OUR CONTACT</h4>
            <address class="mb-lg-4 mb-0">
              <strong>Twitter, Inc.</strong><br />
              1355 Market Street, Suite 900<br />
              San Francisco, CA 94103<br /><br />
              <abbr title="Phone">Phone:</abbr> (123) 456-7890<br />
              <abbr title="Fax">Fax:</abbr> (123) 456-7891<br />
              <abbr title="Email">Email:</abbr>
              <a href="mailto:sales@myshop.com">sales@myshop.com</a><br />
              <abbr title="Skype">Skype:</abbr> <a href="skype:myshop">myshop</a>
            </address>
          </div>
          <!-- END col-3 -->
        </div>
        <!-- END row -->
      </div>
      <!-- END container -->
    </div>
    <!-- END #footer -->

    <!-- BEGIN #footer-copyright -->
    <div id="footer-copyright" class="footer-copyright">
      <!-- BEGIN container -->
      <div class="container">
        <div class="payment-method">
          <img src="assets/img/payment/payment-method.png" alt="" />
        </div>
        <div class="copyright">
          Copyright &copy; 2021 SeanTheme. All rights reserved.
        </div>
      </div>
      <!-- END container -->
    </div>
    <!-- END #footer-copyright -->

    <ThemePanel></ThemePanel>
  </div>
</template>

<script>
import LinkTextMenu from "@/components/LinkTextMenu.vue";
import LatestProductLink from "@/components/LatestProductLink.vue";
import ThemePanel from "@/components/ThemePanel.vue";

export default {
  components: { LinkTextMenu, LatestProductLink, ThemePanel },
  name: "Footer",
  props: [""],
  data() {
    return {};
  },
};
</script>
