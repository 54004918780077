<template>
  <div>
    <!-- BEGIN #subscribe -->
    <div id="subscribe" class="section-container">
      <!-- BEGIN container -->
      <div class="container">
        <!-- BEGIN row -->
        <div class="row">
          <!-- BEGIN col-6 -->
          <div class="col-md-6 mb-4 mb-md-0">
            <!-- BEGIN subscription -->
            <div class="subscription">
              <div class="subscription-intro">
                <h4>LET'S STAY IN TOUCH</h4>
                <p>Get updates on sales specials and more</p>
              </div>
              <div class="subscription-form">
                <form name="subscription_form" action="index" method="POST">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      name="email"
                      placeholder="Enter Email Address"
                    />
                    <button type="submit" class="btn btn-inverse">
                      <i class="fa fa-angle-right"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <!-- END subscription -->
          </div>
          <!-- END col-6 -->
          <!-- BEGIN col-6 -->
          <div class="col-md-6">
            <!-- BEGIN social -->
            <div class="social">
              <div class="social-intro">
                <h4>FOLLOW US</h4>
                <p>We want to hear from you!</p>
              </div>
              <div class="social-list">
                <SocialIcon link="#" classList="fab fa-facebook"></SocialIcon>
                <SocialIcon link="#" classList="fab fa-twitter"></SocialIcon>
                <SocialIcon link="#" classList="fab fa-instagram"></SocialIcon>
                <SocialIcon link="#" classList="fab fa-dribbble"></SocialIcon>
                <SocialIcon link="#" classList="fab fa-google"></SocialIcon>
              </div>
            </div>
            <!-- END social -->
          </div>
          <!-- END col-6 -->
        </div>
        <!-- END row -->
      </div>
      <!-- END container -->
    </div>
    <!-- END #subscribe -->
  </div>
</template>

<script>
import SocialIcon from "@/components/SocialIcon.vue";

export default {
  components: { SocialIcon },
  name: "Subscribe",
  props: [""],
  data() {
    return {};
  },
};
</script>
