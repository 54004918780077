<template>
  <!-- BEGIN review -->
  <div class="review">
    <div class="review-info">
      <div class="review-icon"><img v-bind:src="imgSrc" alt="" /></div>
      <div class="review-rate">
        <ul class="review-star" v-html="createStars()">
        </ul>
        ({{ rating }}/5)
      </div>
      <div class="review-name">{{ name }}</div>
      <div class="review-date">{{ reviewDate }}</div>
    </div>
    <div class="review-title">
      {{ title }}
    </div>
    <div class="review-message">
      {{ text }}
    </div>
  </div>
  <!-- END review -->
</template>

<script>
export default {
  name: "ProductReview",
  props: ["imgSrc", "rating", "name", "reviewDate", "title", "text"],
  data() {
    return {

    };
  },
  methods: {
    createStars: function() {
      let stars = "";
      for (let i = 0; i < 5; i++) {
        if (i < this.rating) {
          stars = stars + '<li class="active"><i class="fa fa-star"></i></li>';
        } else {
          stars = stars + '<li class=""><i class="fa fa-star"></i></li>';
        }
      }
      return stars;
    },
  },
};
</script>
