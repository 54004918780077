<template>

    <!-- BEGIN theme-panel -->
    <div class="theme-panel">
      <a href="javascript:;" data-click="theme-panel-expand" class="theme-collapse-btn"
        ><i class="fa fa-cog"></i
      ></a>
      <div class="theme-panel-content">
        <ul class="theme-list clearfix">
          <li>
            <a
              href="javascript:;"
              class="bg-red"
              data-theme="red"
              data-theme-file="../assets/css/e-commerce/theme/red.min.css"
              data-click="theme-selector"
              data-bs-toggle="tooltip"
              data-trigger="hover"
              data-container="body"
              data-bs-title="Red"
              data-original-title=""
              title=""
              >&nbsp;</a
            >
          </li>
          <li>
            <a
              href="javascript:;"
              class="bg-pink"
              data-theme="pink"
              data-theme-file="../assets/css/e-commerce/theme/pink.min.css"
              data-click="theme-selector"
              data-bs-toggle="tooltip"
              data-trigger="hover"
              data-container="body"
              data-bs-title="Pink"
              data-original-title=""
              title=""
              >&nbsp;</a
            >
          </li>
          <li>
            <a
              href="javascript:;"
              class="bg-orange"
              data-theme="orange"
              data-theme-file="../assets/css/e-commerce/theme/orange.min.css"
              data-click="theme-selector"
              data-bs-toggle="tooltip"
              data-trigger="hover"
              data-container="body"
              data-bs-title="Orange"
              data-original-title=""
              title=""
              >&nbsp;</a
            >
          </li>
          <li>
            <a
              href="javascript:;"
              class="bg-yellow"
              data-theme="yellow"
              data-theme-file="../assets/css/e-commerce/theme/yellow.min.css"
              data-click="theme-selector"
              data-bs-toggle="tooltip"
              data-trigger="hover"
              data-container="body"
              data-bs-title="Yellow"
              data-original-title=""
              title=""
              >&nbsp;</a
            >
          </li>
          <li>
            <a
              href="javascript:;"
              class="bg-lime"
              data-theme="lime"
              data-theme-file="../assets/css/e-commerce/theme/lime.min.css"
              data-click="theme-selector"
              data-bs-toggle="tooltip"
              data-trigger="hover"
              data-container="body"
              data-bs-title="Lime"
              data-original-title=""
              title=""
              >&nbsp;</a
            >
          </li>
          <li>
            <a
              href="javascript:;"
              class="bg-green"
              data-theme="green"
              data-theme-file="../assets/css/e-commerce/theme/green.min.css"
              data-click="theme-selector"
              data-bs-toggle="tooltip"
              data-trigger="hover"
              data-container="body"
              data-bs-title="Green"
              data-original-title=""
              title=""
              >&nbsp;</a
            >
          </li>
          <li class="active">
            <a
              href="javascript:;"
              class="bg-teal"
              data-theme-file=""
              data-theme="default"
              data-click="theme-selector"
              data-bs-toggle="tooltip"
              data-trigger="hover"
              data-container="body"
              data-bs-title="Default"
              data-original-title=""
              title=""
              >&nbsp;</a
            >
          </li>
          <li>
            <a
              href="javascript:;"
              class="bg-aqua"
              data-theme="aqua"
              data-theme-file="../assets/css/e-commerce/theme/aqua.min.css"
              data-click="theme-selector"
              data-bs-toggle="tooltip"
              data-trigger="hover"
              data-container="body"
              data-bs-title="Aqua"
              data-original-title=""
              title=""
              >&nbsp;</a
            >
          </li>
          <li>
            <a
              href="javascript:;"
              class="bg-blue"
              data-theme="blue"
              data-theme-file="../assets/css/e-commerce/theme/blue.min.css"
              data-click="theme-selector"
              data-bs-toggle="tooltip"
              data-trigger="hover"
              data-container="body"
              data-bs-title="Blue"
              data-original-title=""
              title=""
              >&nbsp;</a
            >
          </li>
          <li>
            <a
              href="javascript:;"
              class="bg-purple"
              data-theme="purple"
              data-theme-file="../assets/css/e-commerce/theme/purple.min.css"
              data-click="theme-selector"
              data-bs-toggle="tooltip"
              data-trigger="hover"
              data-container="body"
              data-bs-title="Purple"
              data-original-title=""
              title=""
              >&nbsp;</a
            >
          </li>
          <li>
            <a
              href="javascript:;"
              class="bg-indigo"
              data-theme="indigo"
              data-theme-file="../assets/css/e-commerce/theme/indigo.min.css"
              data-click="theme-selector"
              data-bs-toggle="tooltip"
              data-trigger="hover"
              data-container="body"
              data-bs-title="Indigo"
              data-original-title=""
              title=""
              >&nbsp;</a
            >
          </li>
          <li>
            <a
              href="javascript:;"
              class="bg-black"
              data-theme="black"
              data-theme-file="../assets/css/e-commerce/theme/black.min.css"
              data-click="theme-selector"
              data-bs-toggle="tooltip"
              data-trigger="hover"
              data-container="body"
              data-bs-title="Black"
              data-original-title=""
              title=""
              >&nbsp;</a
            >
          </li>
        </ul>
      </div>
    </div>
    <!-- END theme-panel -->

</template>

<script>
export default {
  name: "ThemePanel",
  props: [""],
  data() {
    return {};
  },
};
</script>
