<template>
     <!-- BEGIN review-form -->
              <div class="review-form">
                <form action="product_detail" name="review_form" method="POST">
                  <h2>Write a review</h2>
                  <div class="form-group">
                    <label for="name">Name <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" id="name" />
                  </div>
                  <div class="form-group">
                    <label for="email">Title <span class="text-danger">*</span></label>
                    <input type="text" class="form-control" id="email" />
                  </div>
                  <div class="form-group">
                    <label for="review">Review <span class="text-danger">*</span></label>
                    <textarea class="form-control" rows="8" id="review"></textarea>
                  </div>
                  <div class="form-group">
                    <label for="email">Rating <span class="text-danger">*</span></label>
                    <div class="rating rating-selection" data-rating="true" data-target="rating">
                      <i class="far fa-star" data-value="2"></i>
                      <i class="far fa-star" data-value="4"></i>
                      <i class="far fa-star" data-value="6"></i>
                      <i class="far fa-star" data-value="8"></i>
                      <i class="far fa-star" data-value="10"></i>
                      <span class="rating-comment">
                        <span class="rating-comment-tooltip">Click to rate</span>
                      </span>
                    </div>
                    <select name="rating" class="hide">
                      <option value="2">2</option>
                      <option value="4">4</option>
                      <option value="6">6</option>
                      <option value="8">8</option>
                      <option value="10">10</option>
                    </select>
                  </div>
                  <button type="submit" class="btn btn-inverse btn-theme btn-lg">
                    Submit Review
                  </button>
                </form>
              </div>
              <!-- END review-form -->
 
</template>

<script>

export default {
  components: {     },
  name: "ProductReviewForm",
  props: [""],
  data() {
    return {
      active: 1,
    };
  },
  methods: {
    makeActive(tab) {
      this.active = tab;
    },
  },
};
</script>
