<template>
  <li>
    <a v-bind:href="link"><img v-bind:src="imgSrc" alt=""/></a>
  </li>
</template>

<script>
export default {
  name: "LinkIconMenu",
  props: ["link", "text", "classList", "imgSrc"],
  data() {
    return {};
  },
};
</script>
