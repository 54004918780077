<template>
  <div>
    <!-- BEGIN #mobile-list -->
    <div id="mobile-list" class="section-container pt-0">
      <!-- BEGIN container -->
      <div class="container">
        <!-- BEGIN section-title -->
        <h4 class="section-title clearfix">
          <span class="flex-1">
            Mobile Phones
            <small>Shop and get your favourite phone at amazing prices!</small>
          </span>
          <a href="#" class="btn">SHOW ALL</a>
        </h4>
        <!-- END section-title -->
        <!-- BEGIN category-container -->
        <div class="category-container">
          <!-- BEGIN category-sidebar -->
          <div class="category-sidebar">
            <ul class="category-list">
              <li class="list-header">Top Categories</li>

              <LinkTextList
                v-for="(tab, index) in tabs"
                v-bind:key="index"
                v-bind:link="'#' + tab"
                v-bind:text="tab"
                v-on:click.native="changeTab(index)"
              ></LinkTextList>

              <!-- <LinkTextList
                link="#Microsoft"
                text="Microsoft"
                v-on:click.native="changeTab(0)"
              ></LinkTextList>
              <LinkTextList
                link="#Samsung"
                text="Samsung"
                v-on:click.native="changeTab(1)"
              ></LinkTextList>
              <LinkTextList
                link="#Apple"
                text="Apple"
                v-on:click.native="changeTab(2)"
              ></LinkTextList>
              <LinkTextList
                link="#Micromax"
                text="Micromax"
                v-on:click.native="changeTab(3)"
              ></LinkTextList>
              <LinkTextList
                link="#Karbonn"
                text="Karbonn"
                v-on:click.native="changeTab(4)"
              ></LinkTextList>
              <LinkTextList
                link="#Intex"
                text="Intex"
                v-on:click.native="changeTab(5)"
              ></LinkTextList>
              <LinkTextList
                link="#Sony"
                text="Sony"
                v-on:click.native="changeTab(6)"
              ></LinkTextList>
              <LinkTextList link="#HTC" text="HTC" v-on:click.native="changeTab(7)"></LinkTextList>
              <LinkTextList
                link="#HTC"
                text="Asus"
                v-on:click.native="changeTab(8)"
              ></LinkTextList>
              <LinkTextList
                link="#Nokia"
                text="Nokia"
                v-on:click.native="changeTab(9)"
              ></LinkTextList>
              <LinkTextList
                link="#Blackberry"
                text="Blackberry"
                v-on:click.native="changeTab(10)"
              ></LinkTextList>
              <LinkTextList
                link="#All Brands"
                text="All Brands"
                v-on:click.native="changeTab(11)"
              ></LinkTextList> -->
            </ul>
          </div>
          <!-- END category-sidebar -->

          <!-- BEGIN category-detail -->
          <!-- <span v-for="(tab, index) in tabs" v-bind:key="index"> -->
            <div  class="category-detail">
              <!-- {{ tab }} -->
              <!-- BEGIN category-item -->
              <a href="#" class="category-item full">
                <ItemPreview
                  imgSrc="assets/img/product/product-samsung-s7-edge.jpg"
                  title="Samsung Galaxy s7 Edge + Geat 360 + Gear VR"
                  price="799.00"
                  description="Redefine what a phone can do"
                ></ItemPreview>
              </a>

              <!-- BEGIN category-item -->
              <div class="category-item list">
                <!-- BEGIN item-row -->
                <div class="item-row">
                  <!-- BEGIN item -->
                  <ProductItem
                    v-for="(d, i) in itemsRow1Computed"
                    v-bind:key="i"
                    v-bind:data="d"
                  ></ProductItem>
                  <!-- END item -->

                  <!-- <ProductItem
                    imgSrc="assets/img/product/product-iphone.png"
                    title="iPhone 6s Plus<br />16GB"
                    price="739.00"
                    description="3D Touch. 12MP photos. 4K video."
                    discount="15"
                    disPrice="649.00"
                    link="product_detail"
                  ></ProductItem>
               
                  <ProductItem
                    imgSrc="assets/img/product/product-samsung-note5.png"
                    title="Samsung Galaxy Note 5<br />Black"
                    price="799.00"
                    description="Super. Computer. Now in two sizes."
                    discount="32"
                    disPrice="599.00"
                    link="product_detail"
                  ></ProductItem>
                
                  <ProductItem
                    imgSrc="assets/img/product/product-iphone-se.png"
                    title="iPhone SE<br />32/64Gb"
                    price="599.00"
                    description="A big step for small."
                    discount="20"
                    disPrice="499.00"
                    link="product_detail"
                  ></ProductItem> -->
                </div>
                <!-- END item-row -->

                <!-- BEGIN item-row -->
                <div class="item-row">
                  <!-- BEGIN item -->
                  <ProductItem
                    v-for="(d, i) in itemsRow2Computed"
                    v-bind:key="i"
                    v-bind:data="d"
                  ></ProductItem>
                  <!-- END item -->

                  <!-- <ProductItem
                    imgSrc="assets/img/product/product-zenfone2.png"
                    title="Assus ZenFone 2<br />‏(ZE550ML)"
                    price="399.00"
                    description="See What Others Can’t See"
                    discount="15"
                    disPrice="453.00"
                    link="product_detail"
                  ></ProductItem>
                 
                  <ProductItem
                    imgSrc="assets/img/product/product-xperia-z.png"
                    title="Sony Xperia Z<br />Black Color"
                    price="799.00"
                    description="For unexpectedly beautiful moments"
                    discount="32"
                    disPrice="599.00"
                    link="product_detail"
                  ></ProductItem>
 
                  <ProductItem
                    imgSrc="assets/img/product/product-lumia-532.png"
                    title="Microsoft Lumia 531<br />Smartphone Orange"
                    price="199.00"
                    description="1 Year Local Manufacturer Warranty"
                    discount="20"
                    disPrice="99.00"
                    link="product_detail"
                  ></ProductItem> -->
                </div>
                <!-- END item-row -->
              </div>
              <!-- END category-item -->
            </div>
          <!-- </span> -->
          <!-- END category-detail -->
        </div>
        <!-- END category-container -->
      </div>
      <!-- END container -->
    </div>
    <!-- END #mobile-list -->
  </div>
</template>

<script>
import LinkTextList from "@/components/LinkTextList.vue";
import ItemPreview from "@/components/ItemPreview.vue";
import ProductItem from "@/components/ProductItem.vue";

export default {
  components: { LinkTextList, ItemPreview, ProductItem },
  name: "Mobile",
  props: [""],
  data() {
    return {
      activeTab: 0,
      tabs: [
        "Microsoft",
        "Samsung",
        "Apple",
        "Micromax",
        "Karbonn",
        "Intex",
        "Sony",
        "HTC",
        "Nokia",
        "Blackberry",
        "All Brands",
      ],
      items: {
        Microsoft: [
          {
            imgSrc: "assets/img/product/product-iphone.png",
            title: "Microsoft iPhone 6s Plus<br />16GB",
            price: "739.00",
            description: "3D Touch. 12MP photos. 4K video.",
            discount: "15",
            disPrice: "649.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-samsung-note5.png",
            title: "Samsung Galaxy Note 5<br />Black",
            price: "799.00",
            description: "Super. Computer. Now in two sizes.",
            discount: "32",
            disPrice: "599.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-iphone-se.png",
            title: "iPhone SE<br />32/64Gb",
            price: "599.00",
            description: "A big step for small.",
            discount: "20",
            disPrice: "499.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-zenfone2.png",
            title: "Asus ZenFone 2<br />‏(ZE550ML)",
            price: "453.00",
            description: "See What Others Can’t See",
            discount: "15",
            disPrice: "399.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-xperia-z.png",
            title: "Sony Xperia Z<br />Black Color",
            price: "799.00",
            description: "For unexpectedly beautiful moments",
            discount: "32",
            disPrice: "599.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-lumia-532.png",
            title: "Microsoft Lumia 531<br />Smartphone Orange",
            price: "199.00",
            description: "1 Year Local Manufacturer Warranty",
            discount: "20",
            disPrice: "99.00",
            link: "product_detail",
          },
        ],
        Samsung: [
          {
            imgSrc: "assets/img/product/product-iphone.png",
            title: "Samsung iPhone 6s Plus<br />16GB",
            price: "739.00",
            description: "3D Touch. 12MP photos. 4K video.",
            discount: "15",
            disPrice: "649.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-samsung-note5.png",
            title: "Samsung Galaxy Note 5<br />Black",
            price: "799.00",
            description: "Super. Computer. Now in two sizes.",
            discount: "32",
            disPrice: "599.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-iphone-se.png",
            title: "iPhone SE<br />32/64Gb",
            price: "599.00",
            description: "A big step for small.",
            discount: "20",
            disPrice: "499.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-zenfone2.png",
            title: "Asus ZenFone 2<br />‏(ZE550ML)",
            price: "453.00",
            description: "See What Others Can’t See",
            discount: "15",
            disPrice: "399.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-xperia-z.png",
            title: "Sony Xperia Z<br />Black Color",
            price: "799.00",
            description: "For unexpectedly beautiful moments",
            discount: "32",
            disPrice: "599.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-lumia-532.png",
            title: "Microsoft Lumia 531<br />Smartphone Orange",
            price: "199.00",
            description: "1 Year Local Manufacturer Warranty",
            discount: "20",
            disPrice: "99.00",
            link: "product_detail",
          },
        ],
        Apple: [
          {
            imgSrc: "assets/img/product/product-iphone.png",
            title: "Apple iPhone 6s Plus<br />16GB",
            price: "739.00",
            description: "3D Touch. 12MP photos. 4K video.",
            discount: "15",
            disPrice: "649.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-samsung-note5.png",
            title: "Samsung Galaxy Note 5<br />Black",
            price: "799.00",
            description: "Super. Computer. Now in two sizes.",
            discount: "32",
            disPrice: "599.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-iphone-se.png",
            title: "iPhone SE<br />32/64Gb",
            price: "599.00",
            description: "A big step for small.",
            discount: "20",
            disPrice: "499.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-zenfone2.png",
            title: "Asus ZenFone 2<br />‏(ZE550ML)",
            price: "453.00",
            description: "See What Others Can’t See",
            discount: "15",
            disPrice: "399.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-xperia-z.png",
            title: "Sony Xperia Z<br />Black Color",
            price: "799.00",
            description: "For unexpectedly beautiful moments",
            discount: "32",
            disPrice: "599.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-lumia-532.png",
            title: "Microsoft Lumia 531<br />Smartphone Orange",
            price: "199.00",
            description: "1 Year Local Manufacturer Warranty",
            discount: "20",
            disPrice: "99.00",
            link: "product_detail",
          },
        ],
        Micromax: [
          {
            imgSrc: "assets/img/product/product-iphone.png",
            title: "Micromax iPhone 6s Plus<br />16GB",
            price: "739.00",
            description: "3D Touch. 12MP photos. 4K video.",
            discount: "15",
            disPrice: "649.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-samsung-note5.png",
            title: "Samsung Galaxy Note 5<br />Black",
            price: "799.00",
            description: "Super. Computer. Now in two sizes.",
            discount: "32",
            disPrice: "599.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-iphone-se.png",
            title: "iPhone SE<br />32/64Gb",
            price: "599.00",
            description: "A big step for small.",
            discount: "20",
            disPrice: "499.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-zenfone2.png",
            title: "Asus ZenFone 2<br />‏(ZE550ML)",
            price: "453.00",
            description: "See What Others Can’t See",
            discount: "15",
            disPrice: "399.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-xperia-z.png",
            title: "Sony Xperia Z<br />Black Color",
            price: "799.00",
            description: "For unexpectedly beautiful moments",
            discount: "32",
            disPrice: "599.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-lumia-532.png",
            title: "Microsoft Lumia 531<br />Smartphone Orange",
            price: "199.00",
            description: "1 Year Local Manufacturer Warranty",
            discount: "20",
            disPrice: "99.00",
            link: "product_detail",
          },
        ],
        Karbonn: [
          {
            imgSrc: "assets/img/product/product-iphone.png",
            title: "Karbonn iPhone 6s Plus<br />16GB",
            price: "739.00",
            description: "3D Touch. 12MP photos. 4K video.",
            discount: "15",
            disPrice: "649.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-samsung-note5.png",
            title: "Samsung Galaxy Note 5<br />Black",
            price: "799.00",
            description: "Super. Computer. Now in two sizes.",
            discount: "32",
            disPrice: "599.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-iphone-se.png",
            title: "iPhone SE<br />32/64Gb",
            price: "599.00",
            description: "A big step for small.",
            discount: "20",
            disPrice: "499.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-zenfone2.png",
            title: "Asus ZenFone 2<br />‏(ZE550ML)",
            price: "453.00",
            description: "See What Others Can’t See",
            discount: "15",
            disPrice: "399.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-xperia-z.png",
            title: "Sony Xperia Z<br />Black Color",
            price: "799.00",
            description: "For unexpectedly beautiful moments",
            discount: "32",
            disPrice: "599.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-lumia-532.png",
            title: "Microsoft Lumia 531<br />Smartphone Orange",
            price: "199.00",
            description: "1 Year Local Manufacturer Warranty",
            discount: "20",
            disPrice: "99.00",
            link: "product_detail",
          },
        ],
        Intex: [
          {
            imgSrc: "assets/img/product/product-iphone.png",
            title: "Intex iPhone 6s Plus<br />16GB",
            price: "739.00",
            description: "3D Touch. 12MP photos. 4K video.",
            discount: "15",
            disPrice: "649.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-samsung-note5.png",
            title: "Samsung Galaxy Note 5<br />Black",
            price: "799.00",
            description: "Super. Computer. Now in two sizes.",
            discount: "32",
            disPrice: "599.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-iphone-se.png",
            title: "iPhone SE<br />32/64Gb",
            price: "599.00",
            description: "A big step for small.",
            discount: "20",
            disPrice: "499.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-zenfone2.png",
            title: "Asus ZenFone 2<br />‏(ZE550ML)",
            price: "453.00",
            description: "See What Others Can’t See",
            discount: "15",
            disPrice: "399.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-xperia-z.png",
            title: "Sony Xperia Z<br />Black Color",
            price: "799.00",
            description: "For unexpectedly beautiful moments",
            discount: "32",
            disPrice: "599.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-lumia-532.png",
            title: "Microsoft Lumia 531<br />Smartphone Orange",
            price: "199.00",
            description: "1 Year Local Manufacturer Warranty",
            discount: "20",
            disPrice: "99.00",
            link: "product_detail",
          },
        ],
        Sony: [
          {
            imgSrc: "assets/img/product/product-iphone.png",
            title: "Sony iPhone 6s Plus<br />16GB",
            price: "739.00",
            description: "3D Touch. 12MP photos. 4K video.",
            discount: "15",
            disPrice: "649.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-samsung-note5.png",
            title: "Samsung Galaxy Note 5<br />Black",
            price: "799.00",
            description: "Super. Computer. Now in two sizes.",
            discount: "32",
            disPrice: "599.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-iphone-se.png",
            title: "iPhone SE<br />32/64Gb",
            price: "599.00",
            description: "A big step for small.",
            discount: "20",
            disPrice: "499.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-zenfone2.png",
            title: "Asus ZenFone 2<br />‏(ZE550ML)",
            price: "453.00",
            description: "See What Others Can’t See",
            discount: "15",
            disPrice: "399.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-xperia-z.png",
            title: "Sony Xperia Z<br />Black Color",
            price: "799.00",
            description: "For unexpectedly beautiful moments",
            discount: "32",
            disPrice: "599.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-lumia-532.png",
            title: "Microsoft Lumia 531<br />Smartphone Orange",
            price: "199.00",
            description: "1 Year Local Manufacturer Warranty",
            discount: "20",
            disPrice: "99.00",
            link: "product_detail",
          },
        ],
        HTC: [
          {
            imgSrc: "assets/img/product/product-iphone.png",
            title: "HTC iPhone 6s Plus<br />16GB",
            price: "739.00",
            description: "3D Touch. 12MP photos. 4K video.",
            discount: "15",
            disPrice: "649.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-samsung-note5.png",
            title: "Samsung Galaxy Note 5<br />Black",
            price: "799.00",
            description: "Super. Computer. Now in two sizes.",
            discount: "32",
            disPrice: "599.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-iphone-se.png",
            title: "iPhone SE<br />32/64Gb",
            price: "599.00",
            description: "A big step for small.",
            discount: "20",
            disPrice: "499.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-zenfone2.png",
            title: "Asus ZenFone 2<br />‏(ZE550ML)",
            price: "453.00",
            description: "See What Others Can’t See",
            discount: "15",
            disPrice: "399.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-xperia-z.png",
            title: "Sony Xperia Z<br />Black Color",
            price: "799.00",
            description: "For unexpectedly beautiful moments",
            discount: "32",
            disPrice: "599.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-lumia-532.png",
            title: "Microsoft Lumia 531<br />Smartphone Orange",
            price: "199.00",
            description: "1 Year Local Manufacturer Warranty",
            discount: "20",
            disPrice: "99.00",
            link: "product_detail",
          },
        ],
        Nokia: [
          {
            imgSrc: "assets/img/product/product-iphone.png",
            title: "Nokia iPhone 6s Plus<br />16GB",
            price: "739.00",
            description: "3D Touch. 12MP photos. 4K video.",
            discount: "15",
            disPrice: "649.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-samsung-note5.png",
            title: "Samsung Galaxy Note 5<br />Black",
            price: "799.00",
            description: "Super. Computer. Now in two sizes.",
            discount: "32",
            disPrice: "599.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-iphone-se.png",
            title: "iPhone SE<br />32/64Gb",
            price: "599.00",
            description: "A big step for small.",
            discount: "20",
            disPrice: "499.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-zenfone2.png",
            title: "Asus ZenFone 2<br />‏(ZE550ML)",
            price: "453.00",
            description: "See What Others Can’t See",
            discount: "15",
            disPrice: "399.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-xperia-z.png",
            title: "Sony Xperia Z<br />Black Color",
            price: "799.00",
            description: "For unexpectedly beautiful moments",
            discount: "32",
            disPrice: "599.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-lumia-532.png",
            title: "Microsoft Lumia 531<br />Smartphone Orange",
            price: "199.00",
            description: "1 Year Local Manufacturer Warranty",
            discount: "20",
            disPrice: "99.00",
            link: "product_detail",
          },
        ],
        Blackberry: [
          {
            imgSrc: "assets/img/product/product-iphone.png",
            title: "Blackberry iPhone 6s Plus<br />16GB",
            price: "739.00",
            description: "3D Touch. 12MP photos. 4K video.",
            discount: "15",
            disPrice: "649.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-samsung-note5.png",
            title: "Samsung Galaxy Note 5<br />Black",
            price: "799.00",
            description: "Super. Computer. Now in two sizes.",
            discount: "32",
            disPrice: "599.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-iphone-se.png",
            title: "iPhone SE<br />32/64Gb",
            price: "599.00",
            description: "A big step for small.",
            discount: "20",
            disPrice: "499.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-zenfone2.png",
            title: "Asus ZenFone 2<br />‏(ZE550ML)",
            price: "453.00",
            description: "See What Others Can’t See",
            discount: "15",
            disPrice: "399.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-xperia-z.png",
            title: "Sony Xperia Z<br />Black Color",
            price: "799.00",
            description: "For unexpectedly beautiful moments",
            discount: "32",
            disPrice: "599.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-lumia-532.png",
            title: "Microsoft Lumia 531<br />Smartphone Orange",
            price: "199.00",
            description: "1 Year Local Manufacturer Warranty",
            discount: "20",
            disPrice: "99.00",
            link: "product_detail",
          },
        ],
        "All Brands": [
          {
            imgSrc: "assets/img/product/product-iphone.png",
            title: "All Brands iPhone 6s Plus<br />16GB",
            price: "739.00",
            description: "3D Touch. 12MP photos. 4K video.",
            discount: "15",
            disPrice: "649.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-samsung-note5.png",
            title: "Samsung Galaxy Note 5<br />Black",
            price: "799.00",
            description: "Super. Computer. Now in two sizes.",
            discount: "32",
            disPrice: "599.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-iphone-se.png",
            title: "iPhone SE<br />32/64Gb",
            price: "599.00",
            description: "A big step for small.",
            discount: "20",
            disPrice: "499.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-zenfone2.png",
            title: "Asus ZenFone 2<br />‏(ZE550ML)",
            price: "453.00",
            description: "See What Others Can’t See",
            discount: "15",
            disPrice: "399.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-xperia-z.png",
            title: "Sony Xperia Z<br />Black Color",
            price: "799.00",
            description: "For unexpectedly beautiful moments",
            discount: "32",
            disPrice: "599.00",
            link: "product_detail",
          },
          {
            imgSrc: "assets/img/product/product-lumia-532.png",
            title: "Microsoft Lumia 531<br />Smartphone Orange",
            price: "199.00",
            description: "1 Year Local Manufacturer Warranty",
            discount: "20",
            disPrice: "99.00",
            link: "product_detail",
          },
        ],
      },
    };
  },
  methods: {
    changeTab: function(activeTab) {
      this.activeTab = activeTab;
    },
  },
    computed: {
    itemsRow1Computed() {
      let rowItems = [];
      for (let index = 0; index < 3; index++) {
        rowItems.push(this.items[this.tabs[this.activeTab]][index]);
      }
      return rowItems;
    },
    itemsRow2Computed() {
      let rowItems = [];
      for (let index = 3; index < 6; index++) {
        rowItems.push(this.items[this.tabs[this.activeTab]][index]);
      }
      return rowItems;
    },
  },
};
</script>
