<template>
 
    <a v-bind:href="link"> <i v-bind:class="classList"></i></a>

</template>

<script>
export default {
  name: "SocialIcon",
  props: ["classList", "link"],
  data() {
    return {};
  },
};
</script>
