<template>
  <div class="item">
    <div class="item-cover">
      <img v-bind:src="imgSrc" alt="" />
    </div>
    <div class="item-info bottom">
      <h4 class="item-title">{{ title }}</h4>
      <p class="item-desc">{{ description }}</p>
      <div class="item-price">${{ price }}</div>
    </div>
  </div>
</template>

<script>

export default {
  components: {  },
  name: "ItemPreview",
  props: ["imgSrc", "title", "price", "description"],
  data() {
    return {};
  },
};
</script>
