<template>
  <li>
    <a v-bind:href="link">{{ text }} ({{ count }})</a>
  </li>
</template>

<script>

export default {
  name: "SidebarCategoryLink",
  props: ["link", "text", "count"],
  data() {
    return {};
  },
};
</script>
