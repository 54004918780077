<template>
  <li v-bind:class="data.classList"
  v-on:click="onClickButton(data.imgSrc)"
  >
    <a v-bind:href="data.link"><img v-bind:src="data.imgSrc" alt=""
    v-on:click="onClickButton(data.imgSrc)"
    /></a>
  </li>
</template>

<script>
export default {
  components: {},
  name: "ProductThumbnail",
  props: ["link", "imgSrc", "classList", "data"],
  data() {
    return {};
  },
  methods: {
    onClickButton(imgSrc) {
      this.$emit("changeImage", imgSrc);
    },
  },
};
</script>
