<template>
  <!-- begin post-list -->
  <ul class="post-list">
    <PostListItem v-for="(d, i) in data" v-bind:key="i" v-bind:data="d"></PostListItem>
    <!-- v-bind:postDay="d.postDay"
      v-bind:postMonth="d.postMonth"
      v-bind:postLikes="d.postLikes"
      v-bind:postTitle="d.postTitle"
      v-bind:postByLink="d.postByLink"
      v-bind:postByName="d.postByName"
      v-bind:postCommentCount="d.postCommentCount"
      v-bind:postTextPreview="d.postTextPreview"
      v-bind:postPicSrc="d.postPicSrc"
      v-bind:postLink="d.postLink"

      v-bind:postQuote="d.postQuote"
      v-bind:postVideoLink="d.postVideoLink" -->

    <!-- <PostListItem
      postDay="03"
      postMonth="SEPT"
      postLikes="520"
      postTitle="Bootstrap Carousel Blog Post"
      postByLink=""
      postByName="admin"
      postCommentCount="2"
      postTextPreview="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis elit dolor, elementum ut ligula ultricies, aliquet eleifend risus. Vivamus ut auctor sapien. Morbi at nibh id lorem viverra commodo augue dui, in pellentesque odio tempor. Etiam lobortis vel enim vitae facilisis. Suspendisse ac faucibus diam, non malesuada nisl. Maecenas vel aliquam eros, sit amet gravida lacus. nteger dictum, nulla"
      postPicSrc="assets/img/post/post-1.jpg"
      postLink="post_detail"
    ></PostListItem>

    <PostListItem
      postDay="21"
      postMonth="OCT"
      postLikes="1,292"
      postTitle="Demonstration Blog Post"
      postByLink=""
      postByName="admin"
      postCommentCount="12"
      postTextPreview="Pellentesque sit amet lectus at urna tempus tincidunt. Curabitur aliquet nisl ut magna efficitur scelerisque. 
										Mauris molestie elementum massa eget bibendum. Sed mauris tortor, condimentum nec efficitur lobortis, tempus ac metus. 
										Donec molestie, tortor ut rhoncus consectetur, ipsum elit maximus nulla, a vulputate augue massa ac dolor. 
										Quisque euismod ornare cursus. Ut consequat pellentesque mattis"
      postPicSrc="assets/img/post/post-4.jpg"
      postLink="post_detail"
    ></PostListItem>

    <PostListItem
      postDay="18"
      postMonth="OCT"
      postLikes="1,743"
      postTitle="Blog Post Video"
      postByLink=""
      postByName="admin"
      postCommentCount="1,292"
      postTextPreview="Praesent maximus malesuada purus, sit amet auctor velit scelerisque nec. Suspendisse eget pellentesque dui, ut egestas orci. 
										Proin eget massa et magna faucibus pulvinar. Quisque tortor orci, volutpat vel auctor non, varius a augue. Cras non ante arcu. 
										Phasellus sit amet dolor non est dictum convallis vel eu lectus. 
										Etiam consectetur non leo at auctor. Proin porttitor tellus arcu, in accumsan eros tincidunt eget"
      postLink="post_detail"
      postVideoLink="https://www.youtube.com/embed/_5aKcpAhTOk"
    ></PostListItem>

    <PostListItem
      postDay="12"
      postMonth="OCT"
      postLikes="3,673"
      postTitle="Blockquote Post"
      postByLink=""
      postByName="admin"
      postCommentCount="2"
      postTextPreview="Ut vulputate sem id egestas faucibus. Phasellus volutpat malesuada lacus, eu semper enim hendrerit a. 
										Mauris vehicula sapien sit amet eros pharetra dignissim. Quisque sed elit hendrerit, tempor sem ut, faucibus massa. 
										Aliquam rutrum id massa interdum dapibus. Proin augue massa, feugiat eu velit vitae, vulputate ullamcorper turpis. 
										Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas."
      postLink="post_detail"
      postQuote='What is design? Its where you stand with a foot in two worlds - the world of technology and the world of people and human purposes - and you try to bring the two together."'
    ></PostListItem> -->
  </ul>
  <!-- end post-list -->
</template>

<script>
import PostListItem from "@/components/PostListItem";

export default {
  components: {  PostListItem },
  name: "PostList",
  props: ["data"],
  data() {
    return {};
  },
};
</script>
