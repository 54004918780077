<template>
  <div>
    <!-- ================== BEGIN core-css ================== -->
    <link
      href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700"
      rel="stylesheet"
    />
    <link href="assets/css/e-commerce/vendor.min.css" rel="stylesheet" />
    <link href="assets/css/e-commerce/app.min.css" rel="stylesheet" />
    <!-- ================== END core-css ================== -->

    <!-- BEGIN #page-container -->
    <div id="page-container" class="fade show">
      <Navtop></Navtop>
      <NavbarEcom></NavbarEcom>

      <!-- BEGIN #page-header -->
      <div id="page-header" class="section-container page-header-container bg-black">
        <!-- BEGIN page-header-cover -->
        <div class="page-header-cover">
          <img src="assets/img/cover/cover-12.jpg" alt="" />
        </div>
        <!-- END page-header-cover -->
        <!-- BEGIN container -->
        <div class="container">
          <h1 class="page-header"><b>Apple</b> Product</h1>
        </div>
        <!-- END container -->
      </div>
      <!-- BEGIN #page-header -->

      <!-- BEGIN search-results -->
      <div id="search-results" class="section-container">
        <!-- BEGIN container -->
        <div class="container">
          <!-- BEGIN search-container -->
          <div class="search-container">
            <ProductFilter></ProductFilter>

            <ProductResults></ProductResults>
          </div>
          <!-- END search-container -->
        </div>
        <!-- END container -->
      </div>
      <!-- END search-results -->
    </div>
    <!-- END #page-container -->

    <Footer></Footer>
  </div>
</template>

<script>
import Navtop from "@/components/Navtop.vue";
import NavbarEcom from "@/components/NavbarEcom.vue";
import Footer from "@/components/Footer.vue";
import ProductFilter from "@/components/ProductFilter.vue";
import ProductResults from "@/components/ProductResults.vue";

export default {
  components: {
    Footer,
    NavbarEcom,
    Navtop,
    ProductFilter,
    ProductResults,
  },
  name: "ProductPage",

  watch: {
    $route: {
      handler: (to) => {
        document.title = to.meta.title;
      },
      immediate: true,
    },
  },
};
</script>
