<template>
  <!-- begin col-4 -->
  <div class="col-md-4 col-sm-4">
    <div class="service">
      <div v-bind:class="data.classList2"><i v-bind:class="data.classList"></i></div>
      <div class="info">
        <h4 class="title">{{data.title}}</h4>
        <p class="desc">
          {{data.description}}
        </p>
      </div>
    </div>
  </div>
  <!-- end col-4 -->
</template>

<script>


export default {
  components: {  },
  name: "AboutCol",
  props: [ "data"],
  data() {
    return {};
  },
};
</script>
