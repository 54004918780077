<template>
			<!-- BEGIN account-sidebar -->
					<div class="account-sidebar">
						<div class="account-sidebar-cover">
							<img src="assets/img/cover/cover-14.jpg" alt="" />
						</div>
						<div class="account-sidebar-content">
							<h4 class="mb-1 mb-lg-3">Your Account</h4>
							<p class="mb-0 mb-md-2 mb-lg-3">
								Modify an order, track a shipment, and update your account info.
							</p>
							<p class="mb-0 mb-lg-3">
								All you need in one place. All with a few simple clicks.
							</p>
						</div>
					</div>
					<!-- END account-sidebar -->
</template>

<script>

export default {
  components: {},
  name: "AccountSidebar",
  props: [""],
  data() {
    return {};
  },
};
</script>
