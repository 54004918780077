<template>
  <div>
    <!-- BEGIN #promotions -->
    <div id="promotions" class="section-container bg-white">
      <!-- BEGIN container -->
      <div class="container">
        <!-- BEGIN section-title -->
        <h4 class="section-title clearfix">
          <span class="flex-1">
            Exclusive promotions
            <small>from 25 September 2021 - 1 January 2021</small>
          </span>
          <a href="#" class="btn">SHOW ALL</a>
        </h4>
        <!-- END section-title -->

        <!-- BEGIN row -->
        <div class="row gx-2">
          <!-- BEGIN col-6 -->
          <div class="col-lg-6">
            <!-- BEGIN promotion -->
            <PromotionItem
              v-bind:data="promotion1"
              classList="promotion promotion-lg bg-black"
              classList2="promotion-image text-end promotion-image-overflow-bottom"
              classList3="promotion-caption promotion-caption-inverse"
            ></PromotionItem>
            <!-- END promotion -->
          </div>
          <!-- END col-6 -->

          <!-- BEGIN col-3 -->
          <div class="col-lg-3 col-md-6">
            <!-- BEGIN promotion -->
            <PromotionItem
              v-bind:data="promotion2"
              classList="promotion bg-blue"
              classList2="promotion-image promotion-image-overflow-bottom promotion-image-overflow-top"
              classList3="promotion-caption promotion-caption-inverse text-end"
            ></PromotionItem>
            <!-- END promotion -->

            <!-- BEGIN promotion -->
            <PromotionItem
              v-bind:data="promotion3"
              classList="promotion bg-gray-200"
              classList2="promotion-image text-center promotion-image-overflow-bottom"
              classList3="promotion-caption text-center"
            ></PromotionItem>
            <!-- END promotion -->
          </div>
          <!-- END col-3 -->
          <!-- BEGIN col-3 -->
          <div class="col-lg-3 col-md-6">
            <!-- BEGIN promotion -->
            <PromotionItem
              v-bind:data="promotion4"
              classList="promotion bg-gray-200"
              classList2="promotion-image promotion-image-overflow-right promotion-image-overflow-bottom text-end"
              classList3="promotion-caption text-center"
            ></PromotionItem>
            <!-- END promotion -->

            <!-- BEGIN promotion -->
            <PromotionItem
              v-bind:data="promotion5"
              classList="promotion bg-black"
              classList2="promotion-image text-end"
              classList3="promotion-caption promotion-caption-inverse"
            ></PromotionItem>
            <!-- END promotion -->
          </div>
          <!-- END col-3 -->
        </div>
        <!-- END row -->
      </div>
      <!-- END container -->
    </div>
    <!-- END #promotions -->
  </div>
</template>

<script>
import PromotionItem from "@/components/PromotionItem.vue";

export default {
  components: {  PromotionItem },
  name: "Promotion",
  props: ["backSrc", "imgSrc", "title", "subtitle", "price", "classList"],
  data() {
    return {
      promotion1: {
        title: "iPhone 12",
        price: "1,299.00",
        description: "A big step for small.<br />A beloved design. Now with more to love.",
        link: "product",
        imgSrc: "assets/img/product/product-iphone-12.png",
      },
      promotion2: {
        title: "Apple Watch",
        price: "299.00",
        description: "You. At a glance.",
        link: "product",
        imgSrc: "assets/img/product/product-apple-watch-sm.png",
      },
      promotion3: {
        title: "Mac Mini",
        price: "199.00",
        description: "It’s mini in a massive way.",
        link: "product",
        imgSrc: "assets/img/product/product-mac-mini.png",
      },
      promotion4: {
        title: "Apple Accessories",
        price: "99.00",
        description: "Redesigned. Rechargeable. Remarkable.",
        link: "product",
        imgSrc: "assets/img/product/product-mac-accessories.png",
      },
      promotion5: {
        title: "Mac Pro",
        price: "1,299.00",
        description: "Built for creativity on an epic scale.",
        link: "product",
        imgSrc: "assets/img/product/product-mac-pro.png",
      },
    };
  },
};
</script>
