<template>
  <!-- BEGIN product -->
  <div class="product">
    <!-- BEGIN product-detail -->
    <div class="product-detail">

      <!-- BEGIN product-image -->
      <div class="product-image">


        <!-- BEGIN product-thumbnail -->
        <div class="product-thumbnail">
          <ul class="product-thumbnail-list">
            <ProductThumbnail
              v-for="(d, i) in productThumbnails"
              v-bind:key="i"
              v-bind:data="d"
              v-on:changeImage="changeProductActiveImage"
            ></ProductThumbnail>
          </ul>
        </div>
        <!-- END product-thumbnail -->


        <!-- BEGIN product-main-image -->
        <div class="product-main-image" data-id="main-image">
          <Picture v-bind:imgSrc="productActiveImage"></Picture>
        </div>
        <!-- END product-main-image -->
      </div>
      <!-- END product-image -->
      <!-- BEGIN product-info -->
      <div class="product-info">
        <!-- BEGIN product-info-header -->
        <div class="product-info-header">
          <h1 class="product-title">
            <span class="badge bg-primary">41% OFF</span> Apple iPhone 6S Plus (16GB) Silver -
            #89270182
          </h1>
          <ul class="product-category">
            <li><a href="#">iPhone</a></li>
            <li>/</li>
            <li><a href="#">mobile phone</a></li>
            <li>/</li>
            <li><a href="#">electronics</a></li>
            <li>/</li>
            <li><a href="#">lifestyle</a></li>
          </ul>
        </div>
        <!-- END product-info-header -->
        <!-- BEGIN product-warranty -->
        <div class="product-warranty">
          <div class="pull-right">Availability: In stock</div>
          <div><b>1 Year</b> Local Manufacturer Warranty</div>
        </div>
        <!-- END product-warranty -->
        <!-- BEGIN product-info-list -->
        <ul class="product-info-list">
          <LinkTextMenu
            v-for="(d, i) in infoList"
            v-bind:key="i"
            v-bind:text="d.text"
            classList="fa fa-circle"
          ></LinkTextMenu>

          <!-- <li><i class="fa fa-circle"></i> 5.5" Retina HD Display with 3D Touch</li>
          <li><i class="fa fa-circle"></i> Fingerprint-resistant oleophobic coating</li>
          <li><i class="fa fa-circle"></i> A9 chip with 64-bit</li>
          <li><i class="fa fa-circle"></i> Ultrafast 4G LTE Advanced wireless</li>
          <li><i class="fa fa-circle"></i> New 12-megapixel iSight camera</li>
          <li><i class="fa fa-circle"></i> 4k video recording</li>
          <li><i class="fa fa-circle"></i> iOS 9 with Touch ID and Apple Pay</li> -->
        </ul>
        <!-- END product-info-list -->
        <!-- BEGIN product-social -->
        <div class="product-social">
          <ul>
            <li>
              <a
                href="javascript:;"
                class="facebook"
                data-toggle="tooltip"
                data-trigger="hover"
                data-title="Facebook"
                data-placement="top"
                ><i class="fab fa-facebook-f"></i
              ></a>
            </li>
            <li>
              <a
                href="javascript:;"
                class="twitter"
                data-toggle="tooltip"
                data-trigger="hover"
                data-title="Twitter"
                data-placement="top"
                ><i class="fab fa-twitter"></i
              ></a>
            </li>
            <li>
              <a
                href="javascript:;"
                class="google-plus"
                data-toggle="tooltip"
                data-trigger="hover"
                data-title="Google Plus"
                data-placement="top"
                ><i class="fab fa-google-plus-g"></i
              ></a>
            </li>
            <li>
              <a
                href="javascript:;"
                class="whatsapp"
                data-toggle="tooltip"
                data-trigger="hover"
                data-title="Whatsapp"
                data-placement="top"
                ><i class="fab fa-whatsapp"></i
              ></a>
            </li>
            <li>
              <a
                href="javascript:;"
                class="tumblr"
                data-toggle="tooltip"
                data-trigger="hover"
                data-title="Tumblr"
                data-placement="top"
                ><i class="fab fa-tumblr"></i
              ></a>
            </li>
          </ul>
        </div>
        <!-- END product-social -->
        <!-- BEGIN product-purchase-container -->
        <div class="product-purchase-container">
          <div class="product-discount">
            <span class="discount">$869.00</span>
          </div>
          <div class="product-price">
            <div class="price">$749.00</div>
          </div>
          <a href="account" class="btn btn-inverse btn-theme btn-lg w-200px">ADD TO CART</a>
        </div>
        <!-- END product-purchase-container -->
      </div>
      <!-- END product-info -->
    </div>
    <!-- END product-detail -->
    <!-- BEGIN product-tab -->
    <div class="product-tab">
      <!-- BEGIN #product-tab -->
      <ul id="product-tab" class="nav nav-tabs">
        <li class="nav-item">
          <a
            class="nav-link"
            href="#product-description"
            v-on:click="makeActive(1)"
            data-toggle="tab"
            >Product Description</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#information" v-on:click="makeActive(2)" data-toggle="tab"
            >Additional Information</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#reviews" v-on:click="makeActive(3)" data-toggle="tab"
            >Rating & Reviews (5)</a
          >
        </li>
      </ul>
      <!-- END #product-tab -->
      <!-- BEGIN #product-tab-content -->
      <div id="product-tab-content" class="tab-content">
        <!-- BEGIN #product-desc -->
        <div v-if="active == 1" class="tab-pane fade show active" id="product-desc">
          <!-- BEGIN product-desc -->
          <ProductDescription
            v-for="(d, i) in productDescriptions"
            v-bind:key="i"
            v-bind:data="d"
          ></ProductDescription>
          <!-- END product-desc -->

          <!-- <ProductDescription
            imgSrc="assets/img/product/product-main.jpg"
            title="iPhone 6s"
            description="The moment you use iPhone 6s, you know you’ve never felt anything like it. With a single press, 3D Touch lets you do more than ever before. Live Photos bring your memories to life in a powerfully vivid way. And that’s just the beginning. Take a deeper look at iPhone 6s, and you’ll find innovation on every level."
            classList="product-desc"
          ></ProductDescription>

          <ProductDescription
            imgSrc="assets/img/product/product-3dtouch.jpg"
            title="3D Touch"
            description="The original iPhone introduced the world to Multi-Touch, forever changing the way people experience technology. With 3D Touch, you can do things that were never possible before. It senses how deeply you press the display, letting you do all kinds of essential things more quickly and simply. And it gives you real-time feedback in the form of subtle taps from the all-new Taptic Engine."
            classList="product-desc right"
          ></ProductDescription>

          <ProductDescription
            imgSrc="assets/img/product/product-cameras.jpg"
            title="Cameras"
            description="The 12-megapixel iSight camera captures sharp, detailed photos. It takes brilliant 4K video, up to four times the resolution of 1080p HD video. iPhone 6s also takes selfies worthy of a self-portrait with the new 5-megapixel FaceTime HD camera. And it introduces Live Photos, a new way to relive your favorite memories. It captures the moments just before and after your picture and sets it in motion with just the press of a finger."
            classList="product-desc"
          ></ProductDescription>

          <ProductDescription
            imgSrc="assets/img/product/product-technology.jpg"
            title="Technology"
            description="iPhone 6s is powered by the custom-designed 64-bit A9 chip. It delivers performance once found only in desktop computers. You’ll experience up to 70 percent faster CPU performance, and up to 90 percent faster GPU performance for all your favorite graphics-intensive games and apps."
            classList="product-desc right"
          ></ProductDescription>

          <ProductDescription
            imgSrc="assets/img/product/product-design.jpg"
            title="Design"
            description="Innovation isn’t always obvious to the eye, but look a little closer at iPhone 6s and you’ll find it’s been fundamentally improved. The enclosure is made from a new alloy of 7000 Series aluminum — the same grade used in the aerospace industry. The cover glass is the strongest, most durable glass used in any smartphone. And a new rose gold finish joins space gray, silver, and gold."
            classList="product-desc"
          ></ProductDescription> -->
        </div>
        <!-- END #product-desc -->

        <!-- BEGIN #product-info -->
        <div v-if="active == 2" class="tab-pane fade show active" id="product-info">
          <!-- BEGIN table-responsive -->
          <ProductInfo></ProductInfo>
          <!-- END table-responsive -->
        </div>
        <!-- END #product-info -->

        <!-- BEGIN #product-reviews -->
        <div v-if="active == 3" class="tab-pane fade show active" id="product-reviews">
          <!-- BEGIN row -->
          <div class="row row-space-30">
            <!-- BEGIN col-7 -->
            <div class="col-md-7 mb-4 mb-lg-0">
              <!-- BEGIN review -->
              <ProductReview
                imgSrc="assets/img/user/user-2.jpg"
                rating="3"
                name="George"
                reviewDate="24/05/2016 8:40am"
                title="When I buy iPhone from apple.com, is it tied to a carrier or does it come
                  “unlocked”?"
                text="In mauris leo, maximus at pellentesque vel, pharetra vel risus. Aenean in semper
                  velit. Pellentesque habitant morbi tristique senectus et netus et malesuada fames
                  ac turpis egestas. Morbi volutpat mattis neque, at molestie tellus ultricies quis.
                  Ut lobortis odio nec nunc ullamcorper, vitae faucibus augue semper. Sed luctus
                  lobortis nulla ac volutpat. Mauris blandit scelerisque sem."
              ></ProductReview>
              <!-- END review -->

              <!-- BEGIN review -->
              <ProductReview
                imgSrc="assets/img/user/user-3.jpg"
                rating="5"
                name="Steve"
                reviewDate="23/05/2016 8:40am"
                title="Where is the iPhone Upgrade Program available?"
                text="Duis ut nunc sem. Integer efficitur, justo sit amet feugiat hendrerit, arcu nisl
                  elementum dui, in ultricies erat quam at mauris. Class aptent taciti sociosqu ad
                  litora torquent per conubia nostra, per inceptos himenaeos. Donec nec ultrices
                  tellus. Mauris elementum venenatis volutpat."
              ></ProductReview>
              <!-- END review -->

              <!-- BEGIN review -->
              <ProductReview
                imgSrc="assets/img/user/user-4.jpg"
                rating="2"
                name="Alfred"
                reviewDate="23/05/2016 10.02am"
                title="Can I keep my current service plan if I choose the iPhone Upgrade Program?"
                text="Donec vel fermentum quam. Vivamus scelerisque enim eget tristique auctor. Vivamus
                  tempus, turpis iaculis tempus egestas, leo augue hendrerit tellus, et efficitur
                  neque massa at neque. Aenean efficitur eleifend orci at ornare."
              ></ProductReview>
              <!-- END review -->

              <!-- BEGIN review -->
              <ProductReview
                imgSrc="assets/img/user/user-5.jpg"
                rating="5"
                name="Edward"
                reviewDate="22/05/2016 9.30pm"
                title="I have an existing carrier contract or installment plan. Can I purchase with the
                  iPhone Upgrade Program"
                text="Aliquam consequat ut turpis non interdum. Integer blandit erat nec sapien
                  sollicitudin, a fermentum dui venenatis. Nullam consequat at enim et aliquet. Cras
                  mattis turpis quis eros volutpat tristique vel a ligula. Proin aliquet leo mi, et
                  euismod metus placerat sit amet."
              ></ProductReview>
              <!-- END review -->
            </div>
            <!-- END col-7 -->
            <!-- BEGIN col-5 -->
            <div class="col-md-5">
              <!-- BEGIN review-form -->
              <ProductReviewForm></ProductReviewForm>
              <!-- END review-form -->
            </div>
            <!-- END col-5 -->
          </div>
          <!-- END row -->
        </div>
        <!-- END #product-reviews -->
      </div>
      <!-- END #product-tab-content -->
    </div>
    <!-- END product-tab -->
  </div>
  <!-- END product -->
</template>

<script>
import ProductDescription from "@/components/ProductDescription.vue";
import Picture from "@/components/Picture.vue";
import ProductReview from "@/components/ProductReview.vue";
import LinkTextMenu from "@/components/LinkTextMenu.vue";
import ProductThumbnail from "@/components/ProductThumbnail.vue";
import ProductThumbnails from "@/components/ProductThumbnails.vue";
import ProductInfo from "@/components/ProductInfo.vue";
import ProductReviewForm from "@/components/ProductReviewForm.vue";

export default {
  components: {
    ProductThumbnail,
    ProductThumbnails,
    ProductDescription,
    Picture,
    ProductReview,
    LinkTextMenu,
    ProductInfo,
    ProductReviewForm,
  },
  name: "ProductDetail",
  props: [""],
  data() {
    return {
      productActiveImage: "assets/img/product/product-iphone-6s-plus.png",
      active: 1,
      productThumbnails: [
        {
          link: "#",
          classList: "active",
          imgSrc: "assets/img/product/product-iphone-6s-plus-2.jpg",
        },
        {
          link: "#",
          classList: "",
          imgSrc: "assets/img/product/product-iphone-6s-plus.png",
        },
        {
          link: "#",
          classList: "",
          imgSrc: "assets/img/product/product-iphone-6s-plus-3.jpg",
        },
        {
          link: "#",
          classList: "",
          imgSrc: "assets/img/product/product-iphone-6s-plus-4.jpg",
        },
        {
          link: "#",
          classList: "",
          imgSrc: "assets/img/product/product-iphone-6s-plus-5.jpg",
        },
        {
          link: "#",
          classList: "",
          imgSrc: "assets/img/product/product-iphone-6s-plus-6.jpg",
        },
        {
          link: "#",
          classList: "",
          imgSrc: "assets/img/product/product-iphone-6s-plus-7.jpg",
        },
      ],
      infoList: [
        { text: '5.5" Retina HD Display with 3D Touch' },
        { text: "Fingerprint-resistant oleophobic coating" },
        { text: "A9 chip with 64-bit" },
        { text: "Ultrafast 4G LTE Advanced wireless" },
        { text: "New 12-megapixel iSight camera" },
        { text: "4k video recording" },
        { text: "iOS 9 with Touch ID and Apple Pay" },
      ],

      productDescriptions: [
        {
          imgSrc: "assets/img/product/product-main.jpg",
          title: "iPhone 6s",
          description:
            "The moment you use iPhone 6s, you know you’ve never felt anything like it. With a single press, 3D Touch lets you do more than ever before. Live Photos bring your memories to life in a powerfully vivid way. And that’s just the beginning. Take a deeper look at iPhone 6s, and you’ll find innovation on every level.",
          classList: "product-desc",
        },
        {
          imgSrc: "assets/img/product/product-3dtouch.jpg",
          title: "3D Touch",
          description:
            "The original iPhone introduced the world to Multi-Touch, forever changing the way people experience technology. With 3D Touch, you can do things that were never possible before. It senses how deeply you press the display, letting you do all kinds of essential things more quickly and simply. And it gives you real-time feedback in the form of subtle taps from the all-new Taptic Engine.",
          classList: "product-desc right",
        },
        {
          imgSrc: "assets/img/product/product-cameras.jpg",
          title: "Cameras",
          description:
            "The 12-megapixel iSight camera captures sharp, detailed photos. It takes brilliant 4K video, up to four times the resolution of 1080p HD video. iPhone 6s also takes selfies worthy of a self-portrait with the new 5-megapixel FaceTime HD camera. And it introduces Live Photos, a new way to relive your favorite memories. It captures the moments just before and after your picture and sets it in motion with just the press of a finger.",
          classList: "product-desc",
        },
        {
          imgSrc: "assets/img/product/product-technology.jpg",
          title: "Technology",
          description:
            "iPhone 6s is powered by the custom-designed 64-bit A9 chip. It delivers performance once found only in desktop computers. You’ll experience up to 70 percent faster CPU performance, and up to 90 percent faster GPU performance for all your favorite graphics-intensive games and apps.",
          classList: "product-desc right",
        },
        {
          imgSrc: "assets/img/product/product-design.jpg",
          title: "Design",
          description:
            "Innovation isn’t always obvious to the eye, but look a little closer at iPhone 6s and you’ll find it’s been fundamentally improved. The enclosure is made from a new alloy of 7000 Series aluminum — the same grade used in the aerospace industry. The cover glass is the strongest, most durable glass used in any smartphone. And a new rose gold finish joins space gray, silver, and gold.",
          classList: "product-desc",
        },
      ],
    };
  },
  methods: {
    makeActive(tab) {
      this.active = tab;
    },
    changeProductActiveImage(imgSrc) {
      let updateClass = this.productThumbnails
      updateClass.forEach(element => {
        element.classList = ""
        if(element.imgSrc === imgSrc){
          element.classList = "active"
        }
      });
      this.productActiveImage = imgSrc
    },
  },


};
</script>
