<template>
  <!-- BEGIN product-thumbnail -->
  <div class="product-thumbnail">
    <ul class="product-thumbnail-list">
      <ProductThumbnail 
        v-for="(d, i) in data"
        v-bind:key="i"
        v-bind:data="d"
      ></ProductThumbnail>

      <!-- <ProductThumbnail
        link="#"
        classList="active"
        imgSrc="assets/img/product/product-iphone-6s-plus-2.jpg"
      ></ProductThumbnail>

      <ProductThumbnail
        link="#"
        classList=""
        imgSrc="assets/img/product/product-iphone-6s-plus.png"
      ></ProductThumbnail>

      <ProductThumbnail
        link="#"
        classList=""
        imgSrc="assets/img/product/product-iphone-6s-plus-3.jpg"
      ></ProductThumbnail>

      <ProductThumbnail
        link="#"
        classList=""
        imgSrc="assets/img/product/product-iphone-6s-plus-4.jpg"
      ></ProductThumbnail>

      <ProductThumbnail
        link="#"
        classList=""
        imgSrc="assets/img/product/product-iphone-6s-plus-5.jpg"
      ></ProductThumbnail>

      <ProductThumbnail
        link="#"
        classList=""
        imgSrc="assets/img/product/product-iphone-6s-plus-6.jpg"
      ></ProductThumbnail>

      <ProductThumbnail
        link="#"
        classList=""
        imgSrc="assets/img/product/product-iphone-6s-plus-7.jpg"
      ></ProductThumbnail> -->
    </ul>
  </div>
  <!-- END product-thumbnail -->
</template>

<script>
import ProductThumbnail from "@/components/ProductThumbnail";

export default {
  components: {  ProductThumbnail },
  name: "ProductThumbnails",
  props: ["data"],
  data() {
    return {};
  },
};
</script>
