<template>
  <div>
    <!-- ================== BEGIN core-css ================== -->
    <link
      href="https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700"
      rel="stylesheet"
    />
    <link href="../assets/css/e-commerce/vendor.min.css" rel="stylesheet" />
    <link href="../assets/css/e-commerce/app.min.css" rel="stylesheet" />
    <!-- ================== END core-css ================== -->

    <!-- BEGIN #page-container -->
    <div id="page-container" class="fade show">
      <Navtop></Navtop>
      <NavbarEcom></NavbarEcom>
      <Slider></Slider>
      <!-- <SliderAgile></SliderAgile> -->

      
      <Promotion></Promotion>
      <Trending></Trending>
      <Mobile></Mobile>
      <Tablet></Tablet>
      <Policy></Policy>
      <Subscribe></Subscribe>
    </div>
    <!-- END #page-container -->

    <Footer></Footer>
  </div>
</template>

<script>
import Navtop from "@/components/Navtop.vue";
import NavbarEcom from "@/components/NavbarEcom.vue";
import Slider from "@/components/Slider.vue";
import Promotion from "@/components/Promotion.vue";
import Trending from "@/components/Trending.vue";
import Mobile from "@/components/Mobile.vue";
import Tablet from "@/components/Tablet.vue";
import Policy from "@/components/Policy.vue";
import Subscribe from "@/components/Subscribe.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Slider,
    Footer,
    NavbarEcom,
    Navtop,
    Promotion,
    Trending,
    Mobile,
    Tablet,
    Policy,
    Subscribe,
    
  },
  name: "ProductMainPage",

  watch: {
    $route: {
      handler: (to) => {
        document.title = to.meta.title;
      },
      immediate: true,
    },
  },
};
</script>
