<template>
  <li>
    <div class="cart-item-image">
      <a v-bind:href="link">
      <img v-bind:src="imgSrc" alt="" /></a>
    </div>
    <div class="cart-item-info">
      <h4>{{ name }}</h4>
      <p class="price">${{ price }}</p>
    </div>
    <div class="cart-item-close">
      <a v-bind:href="link" data-bs-toggle="tooltip" data-bs-title="Remove">&times;</a>
    </div>
  </li>
</template>

<script>
export default {
  name: "CartListItem",
  props: ["link", "name", "price", "imgSrc"],
  data() {
    return {};
  },
};
</script>
