<template>
  <!-- BEGIN product-desc -->
  <div v-bind:class="data.classList">
    <div class="image">
      <img v-bind:src="data.imgSrc" alt="" />
    </div>
    <div class="desc">
      <h4>{{ data.title }}</h4>
      <p>{{ data.description }}</p>
    </div>
  </div>
  <!-- END product-desc -->
</template>

<script>

export default {
  components: {  },
  name: "ProductDescription",
  props: [ "data"],
  data() {
    return {};
  },
};
</script>
