<template>
  <div>
    <!-- begin #footer -->
    <div id="footer" class="footer">
      <!-- begin container -->
      <div class="container">
        <!-- begin row -->
        <div class="row">
          <!-- begin col-3 -->
          <div class="col-md-3 col-6">
            <!-- begin section-container -->
            <div class="section-container">
              <h4 class="footer-title">Categories</h4>
              <ul class="categories">
                <li><a href="#">Sports</a></li>
                <li><a href="#">Outdoor Sports</a></li>
                <li><a href="#">Indoor Sports</a></li>
                <li><a href="#">Video Shooting</a></li>
                <li><a href="#">Drone</a></li>
                <li><a href="#">Uncategorized</a></li>
              </ul>
            </div>
            <!-- end section-container -->
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-md-3 col-6">
            <!-- begin section-container -->
            <div class="section-container">
              <h4 class="footer-title">Archives</h4>
              <ul class="archives">
                <li><a href="#">June 2018</a> <span class="total">(102)</span></li>
                <li><a href="#">May 2018</a> <span class="total">(46)</span></li>
                <li><a href="#">April 2018</a> <span class="total">(84)</span></li>
                <li><a href="#">March 2018</a> <span class="total">(67)</span></li>
                <li><a href="#">February 2018</a> <span class="total">(99)</span></li>
                <li><a href="#">January 2018</a> <span class="total">(113)</span></li>
                <li><a href="#">December 2017</a> <span class="total">(25)</span></li>
              </ul>
            </div>
            <!-- end section-container -->
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-md-3 col-6">
            <!-- begin section-container -->
            <div class="section-container">
              <h4 class="footer-title">Recent Posts</h4>
              <ul class="recent-post">
                <li>
                  <h4>
                    <a href="#">Nam ut urna hendrerit</a>
                    <span class="time">February 22, 2018</span>
                  </h4>
                </li>
                <li>
                  <h4>
                    <a href="#">Class aptent taciti sociosqu</a>
                    <span class="time">July 15, 2018</span>
                  </h4>
                </li>
                <li>
                  <h4>
                    <a href="#">Donec rhoncus arcu</a>
                    <span class="time">March 21, 2018</span>
                  </h4>
                </li>
              </ul>
            </div>
            <!-- end section-container -->
          </div>
          <!-- end col-3 -->
          <!-- begin col-3 -->
          <div class="col-md-3 col-6">
            <div class="section-container">
              <h4 class="footer-title">About Color Admin</h4>
              <address>
                <strong>Twitter, Inc.</strong><br />
                795 Folsom Ave, Suite 600<br />
                San Francisco, CA 94107<br />
                P: (123) 456-7890<br />
                <br />
                <strong>Full Name</strong><br />
                <a href="#">first.last@example.com</a>
              </address>
            </div>
            <!-- end section-container -->
          </div>
          <!-- end col-3 -->
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end #footer -->

    <!-- begin #footer-copyright -->
    <div id="footer-copyright" class="footer-copyright">
      <!-- begin container -->
      <div class="container d-sm-flex">
        <span class="copyright d-block">&copy; 2021 SeanTheme All Right Reserved</span>
        <ul class="social-media-list mt-2 mt-sm-0 flex-1">
          <SocialIconList link="#" classList="fab fa-facebook"></SocialIconList>
          <SocialIconList link="#" classList="fab fa-google-plus"></SocialIconList>
          <SocialIconList link="#" classList="fab fa-instagram"></SocialIconList>
          <SocialIconList link="#" classList="fab fa-twitter"></SocialIconList>
          <SocialIconList link="#" classList="fa fa-rss"></SocialIconList>
        </ul>
      </div>
      <!-- end container -->
    </div>
    <!-- end #footer-copyright -->

    <ThemePanel></ThemePanel>
  </div>
</template>

<script>
import ThemePanel from "@/components/ThemePanel";
import SocialIconList from "@/components/SocialIconList";

export default {
  components: {  ThemePanel, SocialIconList },
  name: "FooterBlog",

  data() {
    return {};
  },
};
</script>
