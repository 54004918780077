<template>
  <li>
    <a  v-if="link" v-bind:href="link">{{ text }}</a>
      <a v-if="data" v-bind:href="data.link" v-bind:class="data.classList" >{{ data.text }}</a>
  </li>
</template>

<script>
export default {
  name: "LinkTextList",
  props: ["link", "text", "data"],
  data() {
    return {};
  },
};
</script>
