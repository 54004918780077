import Vue from "vue";
import VueRouter from "vue-router";

// import blog views
import BlogMainPage from "@/views/BlogMainPage";
import PostDetailPage from "@/views/PostDetailPage";
import PostGridPage from "@/views/PostGridPage";

// import ecom views
import ProductMainPage from "@/views/ProductMainPage";
import ProductMainPageAgile from "@/views/ProductMainPageAgile";
import ProductDetailPage from "@/views/ProductDetailPage";
import ProductPage from "@/views/ProductPage";
import AboutPage from "@/views/AboutPage";
import AccountPage from "@/views/AccountPage";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "BlogMainPage",
    meta: {
      title: "Color Admin | Blog Concept Front End Theme",
    },
    component: BlogMainPage,
  },

  {
    path: "/post_detail",
    name: "PostDetailPage",
    meta: {
      title: "Color Admin | Blog Concept Front End Theme",
    },
    component: PostDetailPage,
  },

  {
    path: "/post_grid",
    name: "PostGridPage",
    meta: {
      title: "Color Admin | Blog Concept Front End Theme",
    },
    component: PostGridPage,
  },

  {
    path: "/ecom",
    name: "ProductMainPage",
    meta: {
      title: "Color Admin | E-Commerce Front End Theme",
    },
    component: ProductMainPage,
  },

  {
    path: "/agile",
    name: "ProductMainPageAgile",
    meta: {
      title: "Color Admin | E-Commerce Front End Theme",
    },
    component: ProductMainPageAgile,
  },

  {
    path: "/product_detail",
    name: "",
    meta: {
      title: "Color Admin | E-Commerce Front End Theme",
    },
    component: ProductDetailPage,
  },
  {
    path: "/product",
    name: "",
    meta: {
      title: "Color Admin | E-Commerce Front End Theme",
    },
    component: ProductPage,
  },
  {
    path: "/about",
    name: "",
    meta: {
      title: "Color Admin | E-Commerce Front End Theme",
    },
    component: AboutPage,
  },
  {
    path: "/account",
    name: "",
    meta: {
      title: "Color Admin | E-Commerce Front End Theme",
    },
    component: AccountPage,
  },


];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
