<template>
  <div>
    <!-- BEGIN #header -->
    <div id="header" class="header">
      <!-- BEGIN container -->
      <div class="container">
        <!-- BEGIN header-container -->
        <div class="header-container">
          <!-- BEGIN navbar-toggle -->
          <button
            type="button"
            class="navbar-toggle collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#navbar-collapse"
          >
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <!-- END navbar-toggle -->

          <!-- BEGIN header-logo -->
          <Logo></Logo>
          <!-- END header-logo -->

          <!-- BEGIN header-nav -->
          <div class="header-nav">
            <div class="collapse navbar-collapse" id="navbar-collapse">
              <ul class="nav justify-content-center">
                <li class="active"><router-link to="ecom">Home</router-link></li>
                <li class="dropdown dropdown-full-width dropdown-hover">
                  <a href="#" data-bs-toggle="dropdown">
                    Our Store
                    <b class="caret"></b>
                    <span class="arrow top"></span>
                  </a>
                  <!-- BEGIN dropdown-menu -->
                  <div class="dropdown-menu p-0">
                    <!-- BEGIN dropdown-menu-container -->
                    <div class="dropdown-menu-container">
                      <!-- BEGIN dropdown-menu-sidebar -->
                      <div class="dropdown-menu-sidebar">
                        <h4 class="title">Shop By Categories</h4>
                        <ul class="dropdown-menu-list">
                         <LinkText
                      v-for="(d, i) in linksCategories"
                      v-bind:key="i"
                      v-bind:link="d.link"
                      v-bind:text="d.text"
                      classList="dropdown-item"
                    ></LinkText>

                          <!-- <LinkTextMenu link="#" text="Mobile Phone"></LinkTextMenu>
                          <LinkTextMenu link="#" text="Tablet"></LinkTextMenu>
                          <LinkTextMenu link="#" text="Laptop"></LinkTextMenu>
                          <LinkTextMenu link="#" text="Desktop"></LinkTextMenu>
                          <LinkTextMenu link="#" text="TV"></LinkTextMenu>
                          <LinkTextMenu link="#" text="Speaker"></LinkTextMenu>
                          <LinkTextMenu link="#" text="Gadget"></LinkTextMenu> -->
                        </ul>
                      </div>
                      <!-- END dropdown-menu-sidebar -->
                      <!-- BEGIN dropdown-menu-content -->
                      <div class="dropdown-menu-content">
                        <h4 class="title">Shop By Popular Phone</h4>
                        <div class="row">
                          <div class="col-lg-3">
                            <ul class="dropdown-menu-list">
                              <LinkTextMenu
                                v-for="(d, i) in linksCol1"
                                v-bind:key="i"
                                v-bind:link="d.link"
                                v-bind:text="d.text"
                                classList="fa fa-fw fa-angle-right text-muted"
                              ></LinkTextMenu>

                              <!-- <LinkTextMenu
                                link="#"
                                text="iPhone 7"
                                classList="fa fa-fw fa-angle-right text-muted"
                              ></LinkTextMenu>
                              <LinkTextMenu
                                link="#"
                                text="iPhone 6s"
                                classList="fa fa-fw fa-angle-right text-muted"
                              ></LinkTextMenu>
                              <LinkTextMenu
                                link="#"
                                text="iPhone 6"
                                classList="fa fa-fw fa-angle-right text-muted"
                              ></LinkTextMenu>
                              <LinkTextMenu
                                link="#"
                                text="iPhone 5s"
                                classList="fa fa-fw fa-angle-right text-muted"
                              ></LinkTextMenu>
                              <LinkTextMenu
                                link="#"
                                text="iPhone 5"
                                classList="fa fa-fw fa-angle-right text-muted"
                              ></LinkTextMenu> -->
                            </ul>
                          </div>
                          <div class="col-lg-3">
                            <ul class="dropdown-menu-list">
                              <LinkTextMenu
                                v-for="(d, i) in linksCol2"
                                v-bind:key="i"
                                v-bind:link="d.link"
                                v-bind:text="d.text"
                                classList="fa fa-fw fa-angle-right text-muted"
                              ></LinkTextMenu>

                              <!-- <LinkTextMenu
                                link="#"
                                text="Galaxy S7"
                                classList="fa fa-fw fa-angle-right text-muted"
                              ></LinkTextMenu>
                              <LinkTextMenu
                                link="#"
                                text="Galaxy S7"
                                classList="fa fa-fw fa-angle-right text-muted"
                              ></LinkTextMenu>
                              <LinkTextMenu
                                link="#"
                                text="Galaxy S7"
                                classList="fa fa-fw fa-angle-right text-muted"
                              ></LinkTextMenu> -->
                            </ul>
                          </div>
                          <div class="col-lg-3">
                            <ul class="dropdown-menu-list">
                              <LinkTextMenu
                                v-for="(d, i) in linksCol3"
                                v-bind:key="i"
                                v-bind:link="d.link"
                                v-bind:text="d.text"
                                classList="fa fa-fw fa-angle-right text-muted"
                              ></LinkTextMenu>

                              <!-- <LinkTextMenu
                                link="#"
                                text="Lumia 730"
                                classList="fa fa-fw fa-angle-right text-muted"
                              ></LinkTextMenu>
                              <LinkTextMenu
                                link="#"
                                text="Lumia 730"
                                classList="fa fa-fw fa-angle-right text-muted"
                              ></LinkTextMenu>
                              <LinkTextMenu
                                link="#"
                                text="Lumia 730"
                                classList="fa fa-fw fa-angle-right text-muted"
                              ></LinkTextMenu>
                              <LinkTextMenu
                                link="#"
                                text="Lumia 730"
                                classList="fa fa-fw fa-angle-right text-muted"
                              ></LinkTextMenu>
                              <LinkTextMenu
                                link="#"
                                text="Lumia 730"
                                classList="fa fa-fw fa-angle-right text-muted"
                              ></LinkTextMenu> -->
                            </ul>
                          </div>
                          <div class="col-lg-3">
                            <ul class="dropdown-menu-list">
                              <LinkTextMenu
                                v-for="(d, i) in linksCol4"
                                v-bind:key="i"
                                v-bind:link="d.link"
                                v-bind:text="d.text"
                                classList="fa fa-fw fa-angle-right text-muted"
                              ></LinkTextMenu>

                              <!-- <LinkTextMenu
                                link="#"
                                text="Xperia X"
                                classList="fa fa-fw fa-angle-right text-muted"
                              ></LinkTextMenu>
                              <LinkTextMenu
                                link="#"
                                text="Xperia X"
                                classList="fa fa-fw fa-angle-right text-muted"
                              ></LinkTextMenu>
                              <LinkTextMenu
                                link="#"
                                text="Xperia X"
                                classList="fa fa-fw fa-angle-right text-muted"
                              ></LinkTextMenu>
                              <LinkTextMenu
                                link="#"
                                text="Xperia X"
                                classList="fa fa-fw fa-angle-right text-muted"
                              ></LinkTextMenu> -->
                            </ul>
                          </div>
                        </div>
                        <h4 class="title">Shop By Brand</h4>
                        <ul class="dropdown-brand-list mb-0">
                          <LinkIconMenu
                            v-for="(d, i) in linksBrands"
                            v-bind:key="i"
                            v-bind:link="d.link"
                            v-bind:imgSrc="d.imgSrc"
                          ></LinkIconMenu>

                          <!-- <LinkIconMenu
                            link="#"
                            imgSrc="assets/img/brand/brand-apple.png"
                          ></LinkIconMenu>
                          <LinkIconMenu
                            link="#"
                            imgSrc="assets/img/brand/brand-samsung.png"
                          ></LinkIconMenu>
                          <LinkIconMenu
                            link="#"
                            imgSrc="assets/img/brand/brand-htc.png"
                          ></LinkIconMenu>
                          <LinkIconMenu
                            link="#"
                            imgSrc="assets/img/brand/brand-microsoft.png"
                          ></LinkIconMenu>
                          <LinkIconMenu
                            link="#"
                            imgSrc="assets/img/brand/brand-nokia.png"
                          ></LinkIconMenu>
                          <LinkIconMenu
                            link="#"
                            imgSrc="assets/img/brand/brand-blackberry.png"
                          ></LinkIconMenu>
                          <LinkIconMenu
                            link="#"
                            imgSrc="assets/img/brand/brand-sony.png"
                          ></LinkIconMenu> -->
                        </ul>
                      </div>
                      <!-- END dropdown-menu-content -->
                    </div>
                    <!-- END dropdown-menu-container -->
                  </div>
                  <!-- END dropdown-menu -->
                </li>
                <li class="dropdown dropdown-hover">
                  <a href="#" data-bs-toggle="dropdown">
                    Accessories
                    <b class="caret"></b>
                    <span class="arrow top"></span>
                  </a>

                  <div class="dropdown-menu">
                   

                    <LinkText link="product" text="Mobile Phone" classList="dropdown-item"></LinkText>
                    <LinkText link="product" text="Tablet" classList="dropdown-item"></LinkText>
                    <LinkText link="product" text="TV" classList="dropdown-item"></LinkText>
                    <LinkText link="product" text="Desktop" classList="dropdown-item"></LinkText>
                    <LinkText link="product" text="Laptop" classList="dropdown-item"></LinkText>
                    <LinkText link="product" text="Speaker" classList="dropdown-item"></LinkText>
                    <LinkText link="product" text="Gadget" classList="dropdown-item"></LinkText>
                  </div>
                </li>
                <li><a href="product">New Arrival</a></li>
                <li class="dropdown dropdown-hover">
                  <a href="#" data-bs-toggle="dropdown">
                    Pages
                    <b class="caret"></b>
                    <span class="arrow top"></span>
                  </a>
                  <div class="dropdown-menu">
                    <LinkText link="#" text="Home (Default)" classList="dropdown-item"></LinkText>
                    <LinkText
                      link="#"
                      text="Home (Fixed Header)"
                      classList="dropdown-item"
                    ></LinkText>
                    <LinkText
                      link="#"
                      text="Home (Inverse Header)"
                      classList="dropdown-item"
                    ></LinkText>
                  </div>
                </li>
                <li class="dropdown dropdown-hover">
                  <a href="#" data-bs-toggle="dropdown">
                    <i class="fa fa-search search-btn"></i>
                    <span class="arrow top"></span>
                  </a>
                  <div class="dropdown-menu p-15px">
                    <form action="search_results" method="POST" name="search_form">
                      <div class="input-group">
                        <input type="text" placeholder="Search" class="form-control bg-gray-200" />
                        <button class="btn btn-inverse" type="submit">
                          <i class="fa fa-search"></i>
                        </button>
                      </div>
                    </form>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!-- END header-nav -->
          <!-- BEGIN header-nav -->
          <div class="header-nav">
            <ul class="nav justify-content-end">
              <li class="dropdown dropdown-hover">
                <a href="#" class="header-cart" data-bs-toggle="dropdown">
                  <i class="fa fa-shopping-bag"></i>
                  <span class="total">2</span>
                  <span class="arrow top"></span>
                </a>
                <div class="dropdown-menu dropdown-menu-cart p-0">
                  <div class="cart-header">
                    <h4 class="cart-title">Shopping Bag (1)</h4>
                  </div>
                  <div class="cart-body">
                    <ul class="cart-item">
                      <CartListItem
                        v-for="(d, i) in cartItems"
                        v-bind:key="i"
                        v-bind:link="d.link"
                        v-bind:name="d.name"
                        v-bind:price="d.price"
                        v-bind:imgSrc="d.imgSrc"
                      ></CartListItem>

                      <!-- <CartListItem
                        link="#"
                        name="iPad Pro Wi-Fi 128GB - Silver"
                        price="699.00"
                        imgSrc="assets/img/product/product-ipad.jpg"
                      ></CartListItem>
                      <CartListItem
                        link="#"
                        name="21.5-inch iMac"
                        price="1299.00"
                        imgSrc="assets/img/product/product-imac.jpg"
                      ></CartListItem>
                      <CartListItem
                        link="#"
                        name="iPhone 6s 16GB - Silver"
                        price="649.00"
                        imgSrc="assets/img/product/product-iphone.png"
                      ></CartListItem> -->
                    </ul>
                  </div>
                  <div class="cart-footer">
                    <div class="row gx-2">
                      <div class="col-6">
                        <LinkText
                          link="#"
                          text="View Cart"
                          classList="btn btn-default btn-theme d-block"
                        ></LinkText>
                      </div>

                      <div class="col-6">
                        <LinkText
                          link="#"
                          text="Checkout"
                          classList="btn btn-inverse btn-theme d-block"
                        ></LinkText>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="divider"></li>
              <li>
                <a href="account">
                  <img src="assets/img/user/user-1.jpg" class="user-img" alt="" />
                  <span class="d-none d-xl-inline">Login / Register</span>
                </a>
              </li>
            </ul>
          </div>
          <!-- END header-nav -->
        </div>
        <!-- END header-container -->
      </div>
      <!-- END container -->
    </div>
    <!-- END #header -->
  </div>
</template>

<script>
import Logo from "@/components/Logo.vue";
import LinkTextMenu from "@/components/LinkTextMenu.vue";
import LinkText from "@/components/LinkText.vue";
import LinkIconMenu from "@/components/LinkIconMenu.vue";
import CartListItem from "@/components/CartListItem.vue";

export default {
  components: { Logo, LinkTextMenu, LinkText, LinkIconMenu, CartListItem },
  name: "NavbarEcom",
  props: [""],
  data() {
    return {
      linksCol1: [
        { link: "product_detail", text: "iPhone 7" },
        { link: "product_detail", text: "iPhone 6s" },
        { link: "product_detail", text: "iPhone 6" },
        { link: "product_detail", text: "iPhone 5s" },
        { link: "product_detail", text: "iPhone 5" },
      ],
      linksCol2: [
        { link: "product_detail", text: "Galaxy S7" },
        { link: "product_detail", text: "Galaxy A9" },
        { link: "product_detail", text: "Galaxy J3" },
        { link: "product_detail", text: "Galaxy Note 5" },
        { link: "product_detail", text: "Galaxy S7" },
      ],
      linksCol3: [
        { link: "product_detail", text: "Lumia 730" },
        { link: "product_detail", text: "Lumia 735" },
        { link: "product_detail", text: "Lumia 830" },
        { link: "product_detail", text: "Lumia 820" },
        { link: "product_detail", text: "Lumia Icon" },
      ],
      linksCol4: [
        { link: "product_detail", text: "Xperia X" },
        { link: "product_detail", text: "Xperia Z5" },
        { link: "product_detail", text: "Xperia M5" },
        { link: "product_detail", text: "Xperia C5" },
        { link: "product_detail", text: "Xperia C4" },
      ],
      cartItems: [
        {
          link: "product_detail",
          name: "iPad Pro Wi-Fi 128GB - Silver",
          price: "699.00",
          imgSrc: "assets/img/product/product-ipad.jpg",
        },
        {
          link: "product_detail",
          name: "21.5-inch iMac",
          price: "1299.00",
          imgSrc: "assets/img/product/product-imac.jpg",
        },
        {
          link: "product_detail",
          name: "iPhone 6s 16GB - Silver",
          price: "649.00",
          imgSrc: "assets/img/product/product-iphone.png",
        },
      ],
      linksBrands: [
        { link: "product_detail", imgSrc: "assets/img/brand/brand-apple.png" },
        { link: "product_detail", imgSrc: "assets/img/brand/brand-samsung.png" },
        { link: "product_detail", imgSrc: "assets/img/brand/brand-htc.png" },
        { link: "product_detail", imgSrc: "assets/img/brand/brand-microsoft.png" },
        { link: "product_detail", imgSrc: "assets/img/brand/brand-nokia.png" },
        { link: "product_detail", imgSrc: "assets/img/brand/brand-blackberry.png" },
        { link: "product_detail", imgSrc: "assets/img/brand/brand-sony.png" },
      ],
      linksCategories: [
        { link: "product_detail", text: "Mobile Phone" },
        { link: "product_detail", text: "Tablet" },
        { link: "product_detail", text: "TV" },
        { link: "product_detail", text: "Desktop" },
        { link: "product_detail", text: "Laptop" },
        { link: "product_detail", text: "Speaker" },
        { link: "product_detail", text: "Gadget" },
      ],
    };
  },
};
</script>
