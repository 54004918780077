<template>
  <li>
    <!-- begin post-left-info -->
    <div class="post-left-info">
      <div class="post-date">
        <span class="day">{{ data.postDay }}</span>
        <span class="month">{{ data.postMonth }}</span>
      </div>
      <div class="post-likes">
        <i class="fa fa-heart text-primary"></i>
        <span class="number"> {{ data.postLikes }}</span>
      </div>
    </div>
    <!-- end post-left-info -->
    <!-- begin post-content -->
    <div class="post-content">

     <PostMedia v-bind:data="data" ></PostMedia>

      <!-- begin post-info -->
      <div class="post-info">
        <h4 class="post-title">
          <router-link v-bind:to="data.postLink">{{ data.postTitle }}</router-link>
        </h4>
        <div class="post-by">
          Posted By <LinkText link="#" text="admin"></LinkText><span class="divider">|</span
          ><LinkText link="#" text="Sports"></LinkText>,
          <LinkText link="#" text="Mountain"></LinkText>, <LinkText link="#" text="Bike"></LinkText
          ><span class="divider">|</span> {{ data.postCommentCount }} Comments
        </div>
        <div class="post-desc">{{ data.postTextPreview }} [...]</div>
      </div>
      <!-- end post-info -->
      <!-- begin read-btn-container -->
      <div class="read-btn-container">
        <router-link v-bind:to="data.postLink"
          >Read More <i class="fa fa-angle-double-right"></i
        ></router-link>
      </div>
      <!-- end read-btn-container -->
    </div>
    <!-- end post-content -->
  </li>
</template>

<script>
import LinkText from "@/components/LinkText";
import PostMedia from "@/components/PostMedia";

export default {
  components: { LinkText , PostMedia},
  name: "PostListItem",
  props: [
    "postDay",
    "postMonth",
    "postLikes",
    "postTitle",
    "postByLink",
    "postByName",
    "postCommentCount",
    "postTextPreview",
    "postPicSrc",
    "postLink",
    "postVideoLink",
    "postQuote",
    "data"
  ],
  data() {
    return {};
  },
};
</script>
